import * as ThreeVrm from '@pixiv/three-vrm';
import * as Three from 'three';
import * as Ammo from 'ammo.js';
import { System } from '../../engine/System';
import { RigidBodyComponent } from '../../engine/components/RigidBody.component';
import { CameraComponent } from '../../engine/components/Camera.component';
import { TPControllerComponent } from '../components/TPController.component';
import { AnimatorComponent } from '../../engine/components/Animator.component';
import { FPControllerComponent } from '../components/FPController.component';

/**
 * Third person controller
 */
export class TPControllerSystem extends System {
  protected spherical: Three.Spherical = new Three.Spherical();

  static get code(): string {
    return 't_p_controller';
  }

  public onXRSessionStart() {
    this.componentManager.getComponentsByType(TPControllerComponent).forEach((component) => {
      component.isInitialized = false;
    });
  }

  public onUpdate(dt: number) {
    if (this.app.renderer.xr.isPresenting) return;

    this.componentManager.getComponentsByType(TPControllerComponent).forEach((component) => {
      if (!component.enabled) return;
      if (!component.isInitialized) return this.initializeComponent(component);

      this.clampCameraRotation(component);
      this.updateDampingDistance(component, dt);
      this.updateDampingRotation(component, dt);
      this.clampCameraDistance(component);
      this.updateCameraPosition(component);
      this.updateCharacterVelocity(component);
      this.updateAnimations(component);
    });
  }

  protected initializeComponent(tPControllerComponent: TPControllerComponent): void {
    this.setupVRMCameraMode(tPControllerComponent);
    this.initCameraRotation(tPControllerComponent);
    this.updateCameraPosition(tPControllerComponent);
    tPControllerComponent.isInitialized = true;
  }

  protected updateCharacterVelocity(component: TPControllerComponent): void {
    const rb = component.entity.getComponentOrFail(RigidBodyComponent);
    const velocity = component.sprintIsActive ? component.sprintVelocity : component.baseVelocity;
    const movementVector = new Three.Vector3(component.movementVector.x, 0, component.movementVector.y);

    if (movementVector.length() !== 0) this.syncCharacterRotation(component);

    movementVector.multiplyScalar(velocity).clampLength(-velocity, velocity).applyEuler(component.entity.rotation);

    rb.getBtRigidBodyOrFail().setLinearVelocity(new Ammo.btVector3(
      movementVector.x,
      Math.min(rb.getBtRigidBodyOrFail().getLinearVelocity().y(), 0), // remove jumps on colliders
      movementVector.z,
    ));
  }

  protected clampCameraRotation(component: TPControllerComponent): void {
    component.cameraPhi = Three.MathUtils.clamp(component.cameraPhi, component.minCameraPhi, component.maxCameraPhi);
  }

  protected clampCameraDistance(component: TPControllerComponent): void {
    component.cameraDistance = Three.MathUtils.clamp(
      component.cameraDistance,
      component.minCameraDistance,
      component.maxCameraDistance,
    );
    component.cameraDumpingDistance = Three.MathUtils.clamp(
      component.cameraDumpingDistance,
      component.minCameraDistance,
      component.maxCameraDistance,
    );
  }

  protected setupVRMCameraMode(component: TPControllerComponent): void {
    if (!component.avatarEntity) return;

    const cameraComponent = component.getCameraEntityOrFail().getComponentOrFail(CameraComponent);
    cameraComponent.threeCamera.layers.disable(ThreeVrm.VRMFirstPerson.DEFAULT_FIRSTPERSON_ONLY_LAYER);
    cameraComponent.threeCamera.layers.enable(ThreeVrm.VRMFirstPerson.DEFAULT_THIRDPERSON_ONLY_LAYER);
  }

  protected updateCameraPosition(tPControllerComponent: TPControllerComponent): void {
    this.spherical.set(
      tPControllerComponent.cameraDumpingDistance,
      tPControllerComponent.cameraDumpingPhi,
      tPControllerComponent.cameraDumpingTheta,
    );

    const cameraPosition = new Three.Vector3().setFromSpherical(this.spherical)
      .add(tPControllerComponent.getLookAtEntityOrFail().getWorldPosition(new Three.Vector3()));

    const cameraEntity = tPControllerComponent.getCameraEntityOrFail();
    const cameraComponent = cameraEntity.getComponentOrFail(CameraComponent);

    cameraComponent.entity.position.copy(cameraPosition);
    cameraComponent.threeCamera.position.set(0, 0, 0);
    cameraComponent.threeCamera.lookAt(tPControllerComponent.getLookAtEntityOrFail().getWorldPosition(new Three.Vector3()));
  }

  protected initCameraRotation(component: TPControllerComponent): void {
    component.cameraTheta = component.entity.rotation.y;
    component.cameraDumpingPhi = component.cameraPhi;
    component.cameraDumpingTheta = component.cameraTheta;
    component.cameraDumpingDistance = component.cameraDistance;
  }

  protected syncCharacterRotation(component: FPControllerComponent): void {
    if (!component.cameraEntity) return;

    const { threeCamera } = component.cameraEntity.getComponentOrFail(CameraComponent);
    const lookVector = threeCamera.getWorldDirection(new Three.Vector3());

    const rotor = new Three.Matrix4().lookAt(
      new Three.Vector3(0, 0, 0),
      new Three.Vector3(lookVector.x, 0, lookVector.z),
      new Three.Vector3(0, 1, 0),
    );
    component.entity.rotation.setFromRotationMatrix(rotor);
  }

  protected updateAnimations(component: TPControllerComponent): void {
    const avatarAnimatorComponent = component.getAvatarEntityOrFail().getComponentOrFail(AnimatorComponent);
    const velocity = component.sprintIsActive ? component.sprintVelocity : component.baseVelocity;
    const movementVector = new Three.Vector3(component.movementVector.x, 0, component.movementVector.y);

    if (movementVector.length() === 0) {
      avatarAnimatorComponent.actionName = 'idle';
      return;
    }

    avatarAnimatorComponent.actionName = 'walk';
    const walkVelocityMultiplier = velocity / component.baseVelocity;
    const movementMultiplier = movementVector.clone().clampLength(0, 1).length();
    const normalizedMovement = movementVector.clone().normalize();

    const { parameters } = avatarAnimatorComponent;

    parameters.forwardWeight = normalizedMovement.z < 0 ? Math.abs(normalizedMovement.z) : 0;
    parameters.backwardWeight = normalizedMovement.z > 0 ? Math.abs(normalizedMovement.z) : 0;

    if (parameters.backwardWeight > 0) {
      parameters.leftBackStrafeWeight = normalizedMovement.x > 0 ? Math.abs(normalizedMovement.x) : 0;
      parameters.rightBackStrafeWeight = normalizedMovement.x < 0 ? Math.abs(normalizedMovement.x) : 0;
      parameters.leftStrafeWeight = 0;
      parameters.rightStrafeWeight = 0;
    } else {
      parameters.leftBackStrafeWeight = 0;
      parameters.rightBackStrafeWeight = 0;
      parameters.leftStrafeWeight = normalizedMovement.x < 0 ? Math.abs(normalizedMovement.x) : 0;
      parameters.rightStrafeWeight = normalizedMovement.x > 0 ? Math.abs(normalizedMovement.x) : 0;
    }

    parameters.speed = movementMultiplier * walkVelocityMultiplier;
    parameters.strafeSpeed = movementMultiplier * walkVelocityMultiplier;
    parameters.backStrafeSpeed = movementMultiplier * walkVelocityMultiplier * -1;
  }

  protected updateDampingDistance(component: TPControllerComponent, dt: number): void {
    component.cameraDumpingDistance = this.getDumpingDelta(
      component.cameraDistance,
      component.cameraDumpingDistance,
      component.cameraDistanceDampingFactor,
      component.cameraDistanceDumpingClampFactor,
      dt,
    );
  }

  protected updateDampingRotation(component: TPControllerComponent, dt: number): void {
    component.cameraDumpingTheta = this.getDumpingDelta(
      component.cameraTheta,
      component.cameraDumpingTheta,
      component.cameraRotationDumpingFactor,
      component.cameraRotationDumpingClampFactor,
      dt,
    );

    component.cameraDumpingPhi = this.getDumpingDelta(
      component.cameraPhi,
      component.cameraDumpingPhi,
      component.cameraRotationDumpingFactor,
      component.cameraRotationDumpingClampFactor,
      dt,
    );
  }

  protected getDumpingDelta(
    targetValue: number,
    currentValue: number,
    dumpingFactor: number,
    clampFactor: number,
    dt: number,
  ): number {
    if (targetValue === currentValue) return targetValue;

    const delta = targetValue - currentValue;

    if (Math.abs(delta) < clampFactor) return targetValue;

    const value = delta * dumpingFactor * (60 * dt);
    return currentValue + Three.MathUtils.clamp(value, -Math.abs(delta), Math.abs(delta));
  }
}
