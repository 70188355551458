import * as Three from 'three';
import { Component } from '../../engine/Component';
import { Entity } from '../../engine/Entity';

// todo: make configurable (any primitive), refactor
export class TPControllerComponent extends Component {
  public cameraEntity?: Entity;

  public avatarEntity?: Entity;

  public lookAtEntity?: Entity;

  public isInitialized = false;

  public cameraPhi = Math.PI / 3; // polar angle (vertical)

  public cameraTheta = 0; // azimuth angle (horizontal)

  public cameraDumpingPhi = this.cameraPhi;

  public cameraDumpingTheta = this.cameraTheta;

  public cameraRotationDumpingFactor = 0.45;

  public cameraRotationDumpingClampFactor = 0.001;

  public cameraDistance = 5;

  public maxCameraDistance = 5;

  public minCameraDistance = 0.5;

  public cameraDumpingDistance = 5;

  public cameraDistanceDampingFactor = 0.2;

  public cameraDistanceDumpingClampFactor = 0.01;

  public maxCameraPhi = Math.PI / 2;

  public minCameraPhi = Math.PI / 4;

  public baseVelocity = 1.8;

  public sprintVelocity = 3.6;

  public sprintIsActive = false;

  public movementVector: Three.Vector2 = new Three.Vector2();

  static get code(): string {
    return 't_p_controller';
  }

  public getCameraEntityOrFail(): Entity {
    if (!this.cameraEntity) throw new Error('Camera entity not found');

    return this.cameraEntity;
  }

  public getAvatarEntityOrFail(): Entity {
    if (!this.avatarEntity) throw new Error('Avatar entity not found');

    return this.avatarEntity;
  }

  public getLookAtEntityOrFail(): Entity {
    if (!this.lookAtEntity) throw new Error('LookAt entity not found');

    return this.lookAtEntity;
  }
}
