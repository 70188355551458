import { generateUUID } from 'three/src/math/MathUtils';
import { Component } from './Component';
import { Entity } from './Entity';

export class ComponentManager {
  public componentsByType: Record<string, Component[]> = {};

  public componentsByIndex: Record<number, Component> = {};

  protected componentIndex = 0;

  public makeComponent<T extends typeof Component>(componentType: T, entity: Entity, data?: ConstructorParameters<T>[0]['data']): InstanceType<T> {
    const component = new componentType({ id: generateUUID(), entity, data, index: this.componentIndex++ });

    this.componentsByType[componentType.code] = this.componentsByType[componentType.code] || [];
    this.componentsByType[componentType.code].push(component);
    this.componentsByIndex[component.index] = component;

    return component as InstanceType<T>;
  }

  public getComponent<T extends typeof Component>(componentType: T, entity: Entity): InstanceType<T> | undefined {
    const component = (this.componentsByType[componentType.code] || []).find((_component) => _component.entity === entity);

    if (component) return component as InstanceType<T>;

    return undefined;
  }

  public getComponentsByType<T extends typeof Component>(componentType: T): InstanceType<T>[] {
    return (this.componentsByType[componentType.code] || []) as InstanceType<T>[];
  }

  public getComponentByIndex(index: number): Component | undefined {
    return this.componentsByIndex[index];
  }

  public destroyAllComponents(): void {
    Object.keys(this.componentsByType).forEach((componentCode) => {
      this.componentsByType[componentCode].forEach((component) => {
        component.destroy();
      });
    });

    this.componentsByType = {};
  }
}
