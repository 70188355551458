import { VRButton } from 'three/examples/jsm/webxr/VRButton';
import * as Three from 'three';
import { Spector } from 'spectorjs';
import { EntityManager } from './EntityManager';
import { ComponentManager } from './ComponentManager';
import { System } from './System';
import { SceneManager } from './scene/SceneManager';

export type ApplicationOptions = {
  xrEnabled: boolean;
};

export class Application {
  public entityManager: EntityManager;

  public componentManager: ComponentManager;

  public camera: Three.PerspectiveCamera | null = null;

  public renderer: Three.WebGLRenderer = new Three.WebGLRenderer({
    antialias: true,
    powerPreference: 'high-performance',
    alpha: true,
  });

  public clock: Three.Clock = new Three.Clock();

  public systems: System[] = [];

  public sceneManager: SceneManager;

  protected xrEnabled: boolean;

  // todo: fix bug with xr rotation delay on 1 frame (first frame has wrong rotation)
  protected isFirstRender = true;

  constructor(options: ApplicationOptions) {
    this.xrEnabled = options.xrEnabled;
    this.entityManager = new EntityManager({ app: this });
    this.componentManager = new ComponentManager();
    this.sceneManager = new SceneManager({ app: this });
    this.setupXRLiveReload();
    this.setupRenderer();
    this.setupXRSessionHandling();
  }

  public get targetFramerate(): number {
    return this.renderer.xr.getSession()?.frameRate || 60;
  }

  // todo: refactor
  public run(): void {
    // this.enableSpector();
    this.clock.start();

    this.renderer.setAnimationLoop(() => {
      const delta = this.clock.getDelta();

      this.updateRendererSize();

      this.systems.forEach((system) => {
        if (!this.sceneManager.sceneIsLoaded) return; // todo: think about it
        system.onUpdate(delta);
      });

      if (!this.sceneManager.sceneIsLoaded) { // todo: think about it
        this.renderer.render(new Three.Scene(), new Three.PerspectiveCamera());
        this.renderer.clear(true, true, true);
        this.isFirstRender = true;
        return;
      }

      if (this.camera && this.sceneManager.currentThreeScene) {
        if (this.isFirstRender) {
          this.isFirstRender = false;
          return;
        }
        this.renderer.render(this.sceneManager.currentThreeScene, this.camera);
      }

      this.systems.forEach((system) => system.onAfterRender(delta));
    });
  }

  public destroy(): void {
    this.renderer.dispose();
    this.renderer.domElement.remove();
    this.systems.forEach((system) => system.destroy());
  }

  public getSystem<T extends typeof System>(SystemType: T): InstanceType<T> | undefined {
    const system = this.systems.find((_system) => _system instanceof SystemType);

    if (system) return system as InstanceType<T>;

    return undefined;
  }

  public getSystemOrFail<T extends typeof System>(SystemType: T): InstanceType<T> {
    const system = this.getSystem(SystemType);

    if (!system) throw new Error(`System ${SystemType.code} not found`);

    return system;
  }

  public destroyAllSystems(): void {
    this.systems.forEach((system) => {
      this.removeSystem(system);
      system.destroy();
    });
    this.systems = [];
  }

  public addSystem(system: System): void {
    this.systems.push(system);
    system.onAdded();
  }

  public removeSystem(system: System): void {
    this.systems = this.systems.filter((_system) => _system !== system);
    system.onRemoved();
  }

  protected enableFramerateLimit(limit: number): void {
    // REFACTOR!!!
    this.renderer.xr.addEventListener('sessionstart', () => {
      const session = this.renderer.xr.getSession();

      if (!session) return;

      if (!session.supportedFrameRates?.includes(limit)) return;

      session.onframeratechange = (test) => {
        if (test.session.frameRate !== limit) {
          session.updateTargetFrameRate(limit).catch(() => undefined);
        }
      };

      session.updateTargetFrameRate(limit).catch(() => undefined);
    });
  }

  protected enableSpector(): void {
    const spector = new Spector();
    spector.spyCanvases();
    spector.displayUI();
  }

  protected setupXRLiveReload(): void {
    // temporary solution for fast develop in oculus quest2
    // eslint-disable-next-line
    // @ts-ignore
    if (window?.module?.hot?.addStatusHandler) {
      // eslint-disable-next-line
      // @ts-ignore
      window.module.hot.addStatusHandler((e) => {
        if (e === 'check' && this.renderer) {
          this.renderer.xr.getSession()?.end();
          window.location.reload();
        }
      });
    }
  }

  protected setupRenderer(): void {
    this.renderer.domElement.style.userSelect = 'none';
    this.renderer.domElement.style.outline = 'none';
    this.renderer.domElement.setAttribute('tabindex', '0');
    this.renderer.setClearColor(0x95b1cc);
    this.renderer.setPixelRatio(2);
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.renderer.localClippingEnabled = true;
    this.renderer.outputEncoding = Three.sRGBEncoding;
    this.renderer.toneMapping = Three.ACESFilmicToneMapping;
    this.renderer.toneMappingExposure = 1;
    this.renderer.domElement.oncontextmenu = () => false;

    if (this.xrEnabled) {
      this.renderer.xr.enabled = true;
      document.body.appendChild(VRButton.createButton(this.renderer));
    }

    this.renderer.xr.setReferenceSpaceType('local');
    // this.renderer.xr.setFramebufferScaleFactor(2); // decrease performance
    // this.renderer.xr.setFoveation(0); // decrease performance
    // this.enableFramerateLimit(90); // think about it
    // this.enableSpector(); // think about it
  }

  protected setupXRSessionHandling(): void {
    this.renderer.xr.addEventListener('sessionstart', () => this.systems.forEach((system) => {
      system.onXRSessionStart();
      this.isFirstRender = true;
    }));
    this.renderer.xr.addEventListener('sessionend', () => this.systems.forEach((system) => {
      system.onXRSessionEnd();
      this.isFirstRender = true;
    }));
  }

  protected updateRendererSize(): void {
    if (this.renderer.xr.isPresenting) return;
    if (!this.renderer.domElement.parentElement) return;

    const { clientWidth, clientHeight } = this.renderer.domElement.parentElement;
    const { width, height } = this.renderer.getSize(new Three.Vector2());

    if ((clientWidth === width) && (clientHeight === height)) return;

    this.renderer.setSize(clientWidth, clientHeight, true);
    if (this.camera) {
      this.camera.aspect = clientWidth / clientHeight;
      this.camera.updateProjectionMatrix();
    }
  }
}
