import { Component, ComponentOptions } from '../../engine/Component';

export type TeleportComponentOptions = ComponentOptions & {
  data?: {
    destinationId: string;
  };
};

export class TeleportComponent extends Component {
  public destinationId: string;

  constructor(options: TeleportComponentOptions) {
    super(options);
    this.destinationId = options.data?.destinationId ?? '';
  }

  static get code(): string {
    return 'teleport';
  }
}
