export enum TooltipId {
  StreetTooltip = 'StreetTooltip',
  InfoTooltip = 'InfoTooltip',
  InfoEnabledTooltip = 'InfoEnabledTooltip',
  DayNightTooltip = 'DayNightTooltip',
  MicrophoneTooltip = 'MicrophoneTooltip',
  MicrophoneOffTooltip = 'MicrophoneOffTooltip',
  CartTooltip = 'CartTooltip',
  InviteTooltip = 'InviteTooltip',
  ExitTooltip = 'ExitTooltip',
}
