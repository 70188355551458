import * as Apollo from '@apollo/client';
import { Application } from '../../engine/Application';
import { PlayerControlsComponent } from '../components/PlayerControls.component';
import { PlayerControlsSystem } from '../systems/PlayerControls.system';
import { ShopStreetScene, ShopStreetSceneLoadData } from '../scenes/ShopStreetScene';
import { RigidBodyComponent } from '../../engine/components/RigidBody.component';
import { FPControllerComponent } from '../components/FPController.component';
import { TPControllerComponent } from '../components/TPController.component';
import { XRFPControllerComponent } from '../components/XRFPController.component';

export type PlayerExternalApiOptions = {
  app: Application;
  graphqlClient: Apollo.ApolloClient<Apollo.NormalizedCacheObject>;
};

// todo: need refactoring
export class PlayerExternalApi {
  protected app: Application;

  protected graphqlClient: Apollo.ApolloClient<Apollo.NormalizedCacheObject>;

  constructor(options: PlayerExternalApiOptions) {
    this.app = options.app;
    this.graphqlClient = options.graphqlClient;
  }

  /**
   * Allows you to set the movement vector of the player's character
   * @param x
   * @param y
   */
  public setMovementDirection(x: number, y: number): void {
    // maybe need named character entity
    this.app.componentManager.getComponentsByType(PlayerControlsComponent).forEach((component) => {
      component.virtualMovementDirection.set(x, y);
    });
  }

  /**
   * Allows toggling the player's character controller (first person / third person)
   */
  public toggleCharacterController(): void {
    const playerControlSystem = this.app.getSystem(PlayerControlsSystem);

    if (!playerControlSystem) return;

    // maybe need named character entity
    this.app.componentManager.getComponentsByType(PlayerControlsComponent).forEach((component) => {
      playerControlSystem.toggleCharacterController(component);
    });
  }

  /**
   * Allows you to load a street scene with stores
   * Important: Interrupted loading is not supported
   * If you specify placeId (store location), the character will immediately stand in front of this place
   * @param streetId
   * @param placeId
   */
  public loadShopStreetScene(streetId: string, placeId?: string): Promise<void> {
    if (this.app.sceneManager.sceneIsLoading) return Promise.reject(new Error('Previous scene is already loading'));

    return this.app.sceneManager.loadScene<ShopStreetSceneLoadData>(
      new ShopStreetScene({ graphqlClient: this.graphqlClient }),
      { streetId, placeId },
    );
  }

  /**
   * Teleports a character to a location near the store
   * Works only on the ShopStreetScene
   * @param placeId
   */
  public teleportToStreetSceneShop(placeId: string): void {
    if (this.app.sceneManager.sceneIsLoading) throw new Error('Current scene is not loaded');
    if (!this.app.sceneManager.sceneIsLoaded) throw new Error('Current scene is not loaded');

    const { currentScene } = this.app.sceneManager;

    if (!(currentScene instanceof ShopStreetScene)) throw new Error('Current scene is not ShopStreetScene');

    const { streetGeneratorService } = currentScene;

    if (!streetGeneratorService) throw new Error('Street generator service not found'); // bad

    // maybe need named character entity
    this.app.componentManager.getComponentsByType(PlayerControlsComponent).forEach((component) => {
      const transform = streetGeneratorService.getSpawnTransform(currentScene.threeScene, undefined, placeId);
      const characterEntity = component.entity;

      characterEntity.matrix.copy(transform);
      characterEntity.matrix.decompose(characterEntity.position, characterEntity.quaternion, characterEntity.scale);
      characterEntity.getComponentOrFail(RigidBodyComponent).applyEntityWorldMatrix();
      characterEntity.getComponentOrFail(FPControllerComponent).isInitialized = false;
      characterEntity.getComponentOrFail(TPControllerComponent).isInitialized = false;
      characterEntity.getComponentOrFail(XRFPControllerComponent).isInitialized = false;
    });
  }
}
