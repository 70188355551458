import * as Three from 'three';
import { VRM } from '@pixiv/three-vrm';
import { FBXLoader as ThreeFBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { Component, ComponentOptions } from '../Component';
import { MeshRendererComponent } from './MeshRenderer.component';
import { VrmAnimationConverter } from '../services/VrmAnimationConverter';

export type AnimationActionData = {
  name: string;
  clipsData: {
    name: string;
    clipName: string;
    speedMultiplier?: number;
    activeWeight?: number;
    startAt?: number;
    resizeTo?: number;
    bindings?: {
      activeWeight?: string;
      speedMultiplier?: string;
    };
  }[];
};

export type AnimationSourceData = {
  url: string;
  clipName: string; // todo: or index or array or something else
};

export type AnimationComponentOptions = ComponentOptions & {
  data?: {
    initialActionName?: string;
    animationSources?: AnimationSourceData[];
    actions?: AnimationActionData[];
    parameters?: Record<string, boolean | number>;
  };
};

// todo: refactor!!!
export class AnimatorComponent extends Component {
  public animationSourcesData: AnimationSourceData[] = [];

  public actionsData: AnimationActionData[] = [];

  public threeFbxLoader = new ThreeFBXLoader();

  public threeAnimationClips: Three.AnimationClip[] = [];

  public threeAnimationMixer = new Three.AnimationMixer(new Three.Object3D());

  public threeAnimationActions: Record<string, Three.AnimationAction[]> = {};

  public actionName = '';

  public isReady = false;

  public actionsWeight: Record<string, number> = {};

  public actionClipsWight: Record<string, Record<string, number>> = {};

  public parameters: Record<string, boolean | number> = {};

  constructor(options: AnimationComponentOptions) {
    super(options);
    this.animationSourcesData = options.data?.animationSources ?? [];
    this.actionsData = options.data?.actions ?? [];
    this.actionName = options.data?.initialActionName || '';
    this.initAnimationSource();
  }

  static get code(): string {
    return 'animator';
  }

  protected makeActions(data: Three.Object3D): void {
    // todo: configurable, move something where
    const converter = new VrmAnimationConverter();
    this.threeAnimationClips = converter.convertMixamoClipsToVRM(this.threeAnimationClips, this.getVRMOrFail());
    this.threeAnimationMixer = new Three.AnimationMixer(data);

    this.threeAnimationActions = this.actionsData.reduce<Record<string, Three.AnimationAction[]>>(
      (resultThreeActions, actionData) => {
        this.actionClipsWight[actionData.name] = {};

        resultThreeActions[actionData.name] = actionData.clipsData.map((clipData) => {
          const clip = this.threeAnimationClips.find((_clip) => _clip.name === clipData.clipName);

          if (!clip) throw new Error(`Animation clip ${clipData.name} not found`);

          const clonedClip = clip.clone();
          clonedClip.name = clipData.name;

          if (clipData.resizeTo) {
            const k = clipData.resizeTo / clonedClip.duration;
            clonedClip.tracks.forEach((track) => {
              track.times = track.times.map((time) => {
                return time * k;
              });
            });
            clonedClip.duration = clipData.resizeTo;
          }

          const action = this.threeAnimationMixer.clipAction(clonedClip);

          action.enabled = true;
          action.weight = 0;
          this.actionClipsWight[actionData.name][clonedClip.name] = 0;
          action.stop();

          return action;
        });

        this.actionsWeight[actionData.name] = 0;

        return resultThreeActions;
      }, {},
    );

    this.isReady = true;
  }

  // todo: temporary
  protected getVRMOrFail(): VRM {
    const vrm = this.entity.getComponentOrFail(MeshRendererComponent).getVRM();

    if (!vrm) throw new Error('Vrm not found');

    return vrm;
  }

  protected tryMakeActions(): void {
    const meshComponent = this.entity.getComponentOrFail(MeshRendererComponent);

    if (meshComponent.data.children.length) {
      this.makeActions(meshComponent.data);
    } else {
      meshComponent.events.on('contentAdded', () => {
        this.makeActions(meshComponent.data);
      });
    }
  }

  protected initAnimationSource(): void {
    Promise.all(this.animationSourcesData.map((sourceData) => new Promise((resolve) => {
      this.threeFbxLoader.load(sourceData.url, (content) => {
        const clip = content.animations[0];
        clip.name = sourceData.clipName;

        this.threeAnimationClips.push(clip);
        resolve(undefined);
      });
    }))).then(() => this.tryMakeActions());
  }
}
