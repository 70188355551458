import * as Three from 'three';
import { Application } from '../../../engine/Application';
import { Entity } from '../../../engine/Entity';
import { DashboardSystem } from '../../systems/Dashboard.system/index';
import { DashboardComponent, LinksData } from '../../components/Dashboard.component';
import { UIDocumentComponent } from '../../../engine/components/UIDocument.component';

export function setupDashboard(
  app: Application,
  characterEntity: Entity,
  currentStreetName?: string,
  currentStreetId?: string,
  linksData?: LinksData,
): void {
  const dashboardSystem = app.getSystemOrFail(DashboardSystem);
  const dashboardUIEntity = app.entityManager.makeEntity();
  const dashboardComponent = dashboardUIEntity.addComponent(DashboardComponent);

  dashboardUIEntity.addComponent(UIDocumentComponent);

  if (linksData) dashboardComponent.linksData = linksData;
  if (currentStreetName) dashboardComponent.currentStreetName = currentStreetName;
  if (currentStreetId) dashboardComponent.currentStreetId = currentStreetId;

  dashboardSystem.setupUIEntity(dashboardUIEntity, 0.001);

  characterEntity.add(dashboardUIEntity);
  dashboardUIEntity.position.copy(new Three.Vector3(0, -0.5, -1.5));
  dashboardUIEntity.rotateX(-Math.PI / 6);
}
