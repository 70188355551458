import * as ThreeMeshUI from 'three-mesh-ui';
import * as Three from 'three';
import { Button, CircleButton, Container, Icon, Tooltip } from './elements/index';
import { ButtonId } from './enums/ButtonId';
import { TooltipId } from './enums/TooltipId';
import { DashboardComponent } from '../../components/Dashboard.component';
import { StreetGeneratorService, StreetLinksData } from '../../services/StreetGenerator.service';

export function makeDashboardTemplate(component: DashboardComponent): ThreeMeshUI.Block {
  return Container({
    width: 480 + 806 + 480,
    height: 252,
    fontFamily: '/assets/fonts/fredoka/Fredoka-500-msdf.json',
    fontTexture: '/assets/fonts/fredoka/Fredoka-500.png',
    justifyContent: 'center',
    contentDirection: 'row',
    backgroundOpacity: 0,
  }, [
    CircleButton({
      id: ButtonId.Microphone,
      absolutePosition: new Three.Vector2(0, 252 - 115),
      size: 115,
      icon: Icon({
        imageUrl: '/assets/icons/icon_mic.png',
        width: 57.5,
        height: 57.5,
      }),
      visible: component.microphoneIsActive,
    }, [
      Tooltip({
        id: TooltipId.MicrophoneTooltip,
        absolutePosition: new Three.Vector2(-159 + 115 / 2, -115 / 2 - 24),
        borderRadius: [30, 30, 0, 30],
        label: 'Microphone',
        width: 159,
      }),
    ]),
    CircleButton({
      id: ButtonId.MicrophoneOff,
      absolutePosition: new Three.Vector2(0, 252 - 115),
      size: 115,
      backgroundColor: new Three.Color().setStyle('#d44c4c'),
      icon: Icon({
        imageUrl: '/assets/icons/icon_mic_off.png',
        width: 57.5,
        height: 57.5,
      }),
      visible: !component.microphoneIsActive,
    }, [
      Tooltip({
        id: TooltipId.MicrophoneOffTooltip,
        absolutePosition: new Three.Vector2(-159 + 115 / 2, -115 / 2 - 24),
        borderRadius: [30, 30, 0, 30],
        label: 'Microphone',
        width: 159,
      }),
    ]),
    CircleButton({
      id: ButtonId.DayNight,
      absolutePosition: new Three.Vector2(115 + 40, 64),
      size: 115,
      icon: Icon({
        imageUrl: '/assets/icons/icon_wb_sunny.png',
        width: 57.5,
        height: 57.5,
      }),
    }, [
      Tooltip({
        id: TooltipId.DayNightTooltip,
        absolutePosition: new Three.Vector2(-158 + 115 / 2, -115 / 2 - 24),
        borderRadius: [30, 30, 0, 30],
        label: 'Day / Night',
        width: 158,
      }),
    ]),
    CircleButton({
      id: ButtonId.Info,
      absolutePosition: new Three.Vector2((115 + 40) * 2, 0),
      size: 115,
      icon: Icon({
        imageUrl: '/assets/icons/icon_help.png',
        width: 57.5,
        height: 57.5,
      }),
      visible: !component.infoIsActive,
    }, [
      Tooltip({
        id: TooltipId.InfoTooltip,
        absolutePosition: new Three.Vector2(-95 + 115 / 2, -115 / 2 - 24),
        borderRadius: [30, 30, 0, 30],
        label: 'Info',
        width: 95,
      }),
    ]),
    CircleButton({
      id: ButtonId.InfoEnabled,
      absolutePosition: new Three.Vector2((115 + 40) * 2, 0),
      size: 115,
      icon: Icon({
        imageUrl: '/assets/icons/icon_help.png',
        width: 57.5,
        height: 57.5,
      }),
      visible: component.infoIsActive,
      backgroundColor: new Three.Color().setStyle('#1a52ff'),
    }, [
      Tooltip({
        id: TooltipId.InfoEnabledTooltip,
        absolutePosition: new Three.Vector2(-95 + 115 / 2, -115 / 2 - 24),
        borderRadius: [30, 30, 0, 30],
        label: 'Info',
        width: 95,
      }),
    ]),
    Container({
      width: 806,
      height: 252,
      justifyContent: 'space-between',
      backgroundOpacity: 0,
      autoLayout: false,
    }, [
      Container({
        width: 806,
        height: 60,
        contentDirection: 'row',
        justifyContent: 'space-between',
        backgroundOpacity: 0,
      }, [
        Button({
          width: 254,
          height: 60,
          padding: 8,
          titleFontSize: 18,
          id: ButtonId.TLStreet,
          leftIcon: Icon({
            imageUrl: '/assets/icons/icon_keyboard_arrow_left.png',
            width: 43,
            height: 43,
            visible: !!component.linksData.topLeft.name,
          }),
          title: component.linksData.topLeft.name,
          titleTextAlign: 'right',
          justifyContent: 'start',
          titleWidth: 120,
          titleLineHeight: 24,
        }),
        Button({
          width: 254,
          height: 60,
          padding: 8,
          titleFontSize: 18,
          id: ButtonId.TMStreet,
          leftIcon: Icon({
            imageUrl: '/assets/icons/icon_keyboard_arrow_top.png',
            width: 43,
            height: 43,
            visible: !!component.linksData.topMiddle.name,
          }),
          title: component.linksData.topMiddle.name,
          titleTextAlign: 'left',
          justifyContent: 'center',
          titleWidth: 120,
          titleLineHeight: 24,
        }),
        Button({
          width: 254,
          height: 60,
          padding: 8,
          titleFontSize: 18,
          id: ButtonId.TRStreet,
          rightIcon: Icon({
            imageUrl: '/assets/icons/icon_keyboard_arrow_right.png',
            width: 43,
            height: 43,
            visible: !!component.linksData.topRight.name,
          }),
          title: component.linksData.topRight.name,
          titleTextAlign: 'left',
          justifyContent: 'end',
          titleWidth: 120,
          titleLineHeight: 24,
        }),
      ]),
      Container({
        width: 806,
        height: 80,
        contentDirection: 'row',
        justifyContent: 'space-between',
        backgroundOpacity: 0,
      }, [
        Button({
          id: ButtonId.CurrentStreet,
          width: 806,
          height: 80,
          title: component.currentStreetName,
          titleFontSize: 24,
          titleLineHeight: 32,
          titleWidth: 200,
          padding: 0,
          titleTextAlign: 'center',
          justifyContent: 'center',
        }),
      ]),
      Container({
        width: 806,
        height: 60,
        contentDirection: 'row',
        justifyContent: 'space-between',
        backgroundOpacity: 0,
      }, [
        Button({
          width: 254,
          height: 60,
          padding: 8,
          titleFontSize: 18,
          id: ButtonId.BLStreet,
          leftIcon: Icon({
            imageUrl: '/assets/icons/icon_keyboard_arrow_left.png',
            width: 43,
            height: 43,
            visible: !!component.linksData.bottomLeft.name,
          }),
          title: component.linksData.bottomLeft.name,
          titleTextAlign: 'right',
          justifyContent: 'start',
          titleWidth: 120,
          titleLineHeight: 24,
        }),
        Button({
          width: 254,
          height: 60,
          padding: 8,
          titleFontSize: 18,
          id: ButtonId.BMStreet,
          leftIcon: Icon({
            imageUrl: '/assets/icons/icon_keyboard_arrow_bottom.png',
            width: 43,
            height: 43,
            visible: !!component.linksData.bottomMiddle.name,
          }),
          title: component.linksData.bottomMiddle.name,
          titleTextAlign: 'left',
          justifyContent: 'center',
          titleWidth: 120,
          titleLineHeight: 24,
        }),
        Button({
          width: 254,
          height: 60,
          padding: 8,
          titleFontSize: 18,
          id: ButtonId.BRStreet,
          rightIcon: Icon({
            imageUrl: '/assets/icons/icon_keyboard_arrow_right.png',
            width: 43,
            height: 43,
            visible: !!component.linksData.bottomRight.name,
          }),
          title: component.linksData.bottomRight.name,
          titleTextAlign: 'left',
          justifyContent: 'end',
          titleWidth: 120,
          titleLineHeight: 24,
        }),
      ]),
    ]),
    Tooltip({
      id: TooltipId.StreetTooltip,
      absolutePosition: new Three.Vector2(((480 + 806 + 480) / 2) - 200 / 2, -24 - 54 - 8),
      borderRadius: [30, 30, 30, 30],
      label: 'Adjoining streets',
      width: 200,
    }),
    CircleButton({
      id: ButtonId.Cart,
      absolutePosition: new Three.Vector2(480 + 806 + 480 - ((115 + 40) * 2) - 115, 0),
      size: 115,
      icon: Icon({
        imageUrl: '/assets/icons/icon_local_grocery_store.png',
        width: 57.5,
        height: 57.5,
      }),
    }, [
      Tooltip({
        id: TooltipId.CartTooltip,
        absolutePosition: new Three.Vector2(115 / 2, -115 / 2 - 24),
        borderRadius: [30, 30, 30, 0],
        label: 'Cart',
        width: 101,
      }),
    ]),
    CircleButton({
      id: ButtonId.Invite,
      absolutePosition: new Three.Vector2(480 + 806 + 480 - (115 + 40) - 115, 64),
      size: 115,
      icon: Icon({
        imageUrl: '/assets/icons/icon_person_add.png',
        width: 57.5,
        height: 57.5,
      }),
    }, [
      Tooltip({
        id: TooltipId.InviteTooltip,
        absolutePosition: new Three.Vector2(115 / 2, -115 / 2 - 24),
        borderRadius: [30, 30, 30, 0],
        label: 'Invite friends',
        width: 170,
      }),
    ]),
    CircleButton({
      id: ButtonId.Exit,
      absolutePosition: new Three.Vector2(480 + 806 + 480 - 115, 252 - 115),
      size: 115,
      icon: Icon({
        imageUrl: '/assets/icons/icon_logout.png',
        width: 57.5,
        height: 57.5,
      }),
    }, [
      Tooltip({
        id: TooltipId.ExitTooltip,
        absolutePosition: new Three.Vector2(115 / 2, -115 / 2 - 24),
        borderRadius: [30, 30, 30, 0],
        label: 'Exit',
        width: 170,
      }),
    ]),
  ]);
}
