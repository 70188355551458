import React, { useEffect, useRef, useState } from 'react';
import * as Apollo from '@apollo/client';
import { InMemoryCache } from '@apollo/client';
import { Application } from '../../engine/Application';
import { PlayerExternalApi } from '../../domain/externalApi/PlayerExternalApi';
import { load } from '../../engine/physics/AmmoLoader';
import { LandingZoneScene } from '../../domain/scenes/LandingZoneScene';
import { useTestGUI } from './useTestGUI';

const graphqlClient = new Apollo.ApolloClient({
  uri: 'https://api.wondersouq.web3dev.group/graphql',
  cache: new InMemoryCache(),
  headers: {
    'jwt-token': 'mock',
  },
});

function TextExample() {
  const [playerApi, setPlayerApi] = useState<PlayerExternalApi | null>(null);
  const [ammoIsLoaded, setAmmoIsLoaded] = useState(false);
  const graphqlClientRef = useRef(graphqlClient);

  useEffect(() => {
    load.then(() => setAmmoIsLoaded(true));
  }, []);

  useEffect(() => {
    if (!ammoIsLoaded) return;

    const app = new Application({ xrEnabled: true });

    const playerApiInstance = new PlayerExternalApi({ app, graphqlClient: graphqlClientRef.current });

    app.sceneManager.loadScene(new LandingZoneScene({ graphqlClient: graphqlClientRef.current }), {})
      .then(() => {
        setPlayerApi(playerApiInstance);
        app.run();
      });

    document.body.append(app.renderer.domElement);

    return () => {
      setPlayerApi(null);
      app.destroy();
    };
  }, [ammoIsLoaded]);

  useTestGUI(playerApi, graphqlClientRef.current);

  return (
    <div className="App" />
  );
}

export default TextExample;
