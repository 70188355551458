import * as Three from 'three';
import { Application } from '../Application';

export abstract class AbstractScene {
  protected _threeScene: Three.Scene = new Three.Scene();

  public get threeScene(): Three.Scene {
    return this._threeScene;
  }

  public abstract load(app: Application, data?: unknown): Promise<void>;

  public abstract destroy(app: Application): Promise<void>;
}
