import { ButtonControl } from '../controls/ButtonControl';
import { ButtonSensor } from '../sensors/ButtonSensor';
import { Application } from '../../../Application';

// todo: fill enum
export enum KeyboardKeyCode {
  KeyW = 'KeyW',
  KeyA = 'KeyA',
  KeyS = 'KeyS',
  KeyD = 'KeyD',
  KeyX = 'KeyX',
  KeyB = 'KeyB',
  KeyC = 'KeyC',
  ShiftLeft = 'ShiftLeft',
}

export type KeyboardDeviceOptions = {
  app: Application;
};

export class KeyboardDevice {
  protected app: Application;

  protected undefinedKeyControl: ButtonControl = new ButtonControl();

  protected keyControls: Partial<Record<KeyboardKeyCode, ButtonControl>> = {};

  protected keySensors: Partial<Record<KeyboardKeyCode, ButtonSensor>> = {};

  protected onKeyUpBind = this.onKeyUp.bind(this);

  protected onKeyDownBind = this.onKeyDown.bind(this);

  protected onCanvasBlurBind = this.onCanvasBlur.bind(this);

  protected onCanvasFocusBind = this.onCanvasFocus.bind(this);

  constructor(options: KeyboardDeviceOptions) {
    this.app = options.app;
    this.setupKeys();
    this.setupListeners();
  }

  public getKeyByCode(code: KeyboardKeyCode | string): ButtonControl {
    return this.keyControls[code as KeyboardKeyCode] || this.undefinedKeyControl;
  }

  public update(): void {
    Object.keys(KeyboardKeyCode).forEach((code) => {
      const sensor = this.keySensors[code as KeyboardKeyCode];
      const control = this.keyControls[code as KeyboardKeyCode];

      if (!sensor || !control) return;

      sensor.applyToControls(control);
    });
  }

  public destroy(): void {
    this.removeListeners();
  }

  protected onKeyUp(event: KeyboardEvent): void {
    const code = event.code as KeyboardKeyCode;
    const sensor = this.keySensors[code];

    if (!sensor || event.repeat) return;

    sensor.isPressed = false;
  }

  protected onKeyDown(event: KeyboardEvent): void {
    const code = event.code as KeyboardKeyCode;
    const sensor = this.keySensors[code];

    if (!sensor || event.repeat) return;

    sensor.isPressed = true;
  }

  protected onCanvasBlur(): void {
    Object.keys(KeyboardKeyCode).forEach((code) => {
      const sensor = this.keySensors[code as KeyboardKeyCode];

      if (sensor) {
        sensor.sleep = true;
        sensor.isPressed = false;
      }
    });
  }

  protected onCanvasFocus(): void {
    Object.keys(KeyboardKeyCode).forEach((code) => {
      const sensor = this.keySensors[code as KeyboardKeyCode];

      if (sensor) sensor.sleep = false;
    });
  }

  protected setupListeners(): void {
    window.addEventListener('keydown', this.onKeyDownBind);
    window.addEventListener('keyup', this.onKeyUpBind);
    this.app.renderer.domElement.addEventListener('blur', this.onCanvasBlurBind);
    this.app.renderer.domElement.addEventListener('focus', this.onCanvasFocusBind);
  }

  protected removeListeners(): void {
    window.removeEventListener('keydown', this.onKeyDownBind);
    window.removeEventListener('keyup', this.onKeyUpBind);
    this.app.renderer.domElement.removeEventListener('blur', this.onCanvasBlurBind);
    this.app.renderer.domElement.removeEventListener('focus', this.onCanvasFocusBind);
  }

  protected setupKeys(): void {
    Object.keys(KeyboardKeyCode).forEach((code) => {
      this.keySensors[code as KeyboardKeyCode] = new ButtonSensor();
      this.keyControls[code as KeyboardKeyCode] = new ButtonControl();
    });
  }
}
