import { gql } from '@apollo/client';

export const GetLandingZoneStreets = gql`
    query GetLandingZoneStreets {
        availableStreets(limit: 4) {
            data {
                id
                name
            }
        }
    }
`;
