import { Component } from '../../engine/Component';

export type LinkData = {
  name: string;
  id: string;
};

export type LinksData = {
  topLeft: LinkData;
  topMiddle: LinkData;
  topRight: LinkData;
  bottomLeft: LinkData;
  bottomMiddle: LinkData;
  bottomRight: LinkData;
};

// todo: make configurable (any primitive), refactor
export class DashboardComponent extends Component {
  public infoIsActive = false;

  public microphoneIsActive = true;

  public currentStreetName = '';

  public currentStreetId = '';

  public linksData: LinksData = {
    topLeft: { name: '', id: '' },
    topMiddle: { name: '', id: '' },
    topRight: { name: '', id: '' },
    bottomLeft: { name: '', id: '' },
    bottomMiddle: { name: '', id: '' },
    bottomRight: { name: '', id: '' },
  };

  static get code(): string {
    return 'dashboard_u_i';
  }
}
