import { gql } from '@apollo/client';

export const GetStreet = gql`
    fragment StreetLinks on StreetLinks {
        l {
            target {
                ... on Street {
                    name
                }
            }
            targetId
        }
        r {
            target {
                ... on Street {
                    name
                }
            }
            targetId
        }
        f {
            target {
                ... on Street {
                    name
                }
            }
            targetId
        }
    }

    fragment BlockGlbModelData on BlockGlbModel {
        url
        material
        textures {
            normal
            ao
        }
    }

    query GetStreet($streetId: String!) {
        street(streetId: $streetId) {
            id
            name
            startLinks {
                ...StreetLinks
            }
            endLinks {
                ...StreetLinks
            }
            places {
                id
                status
                holder {
                    holderConfig {
                        ... on ShopConfig {
                            id
                            shop {
                                status
                            }
                            layout {
                                firstFloorInfo {
                                    firstFloor {
                                        model {
                                            ...BlockGlbModelData
                                        }
                                    }
                                    layoutInfo {
                                        doorInfo {
                                            payload {
                                                color
                                                imageUrl
                                            }
                                            door {
                                                model {
                                                    ...BlockGlbModelData
                                                }
                                            }
                                        }
                                        umbrellaInfo {
                                            payload {
                                                color
                                            }
                                            umbrella {
                                                model {
                                                    ...BlockGlbModelData
                                                }
                                            }
                                        }
                                    }
                                    payload {
                                        color
                                    }
                                }
                                roofInfo {
                                    payload {
                                        color
                                    }
                                    roof {
                                        model {
                                            ...BlockGlbModelData
                                        }
                                    }
                                }
                                secondFloorInfo {
                                    payload {
                                        color
                                    }
                                    secondFloor {
                                        model {
                                            ...BlockGlbModelData
                                        }
                                    }
                                }
                                signAreaInfo {
                                    payload {
                                        color
                                        imageUrl
                                    }
                                    signArea {
                                        model {
                                            ...BlockGlbModelData
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                stubInfo{
                    color
                    stub {
                        id
                        model {
                            material
                            url
                        }
                    }
                }
            }
        }
    }
`;
