export enum ButtonId {
  TLStreet = 'TLStreet',
  TMStreet = 'TMStreet',
  TRStreet = 'TRStreet',
  CurrentStreet = 'CurrentStreet',
  BLStreet = 'BLStreet',
  BMStreet = 'BMStreet',
  BRStreet = 'BRStreet',
  Info = 'Info',
  InfoEnabled = 'InfoEnabled',
  DayNight = 'DayNight',
  Microphone = 'Microphone',
  MicrophoneOff = 'MicrophoneOff',
  Cart = 'Cart',
  Invite = 'Invite',
  Exit = 'Exit',
}
