import * as Apollo from '@apollo/client';
import { System, SystemOptions } from '../../engine/System';
import { TeleportComponent } from '../components/Teleport.component';
import { ColliderComponent } from '../../engine/components/Collider.component';
import { TPControllerComponent } from '../components/TPController.component';
import { ShopStreetScene, ShopStreetSceneLoadData, ShopStreetSceneOptions } from '../scenes/ShopStreetScene';

export type TeleportSystemOptions = SystemOptions & {
  currentStreetId?: string;
  graphqlClient: Apollo.ApolloClient<Apollo.NormalizedCacheObject>;
};

/**
 * Third person controller
 */
export class TeleportSystem extends System {
  protected graphqlClient: Apollo.ApolloClient<Apollo.NormalizedCacheObject>;

  protected currentStreetId?: string;

  constructor(options: TeleportSystemOptions) {
    super(options);
    this.graphqlClient = options.graphqlClient;
    this.currentStreetId = options.currentStreetId;
  }

  static get code(): string {
    return 'teleport';
  }

  onUpdate() {
    this.componentManager.getComponentsByType(TeleportComponent).forEach((teleportComponent) => {
      if (!this.app.sceneManager.sceneIsLoaded) return;

      const colliderComponent = teleportComponent.entity.getComponentOrFail(ColliderComponent);
      const overlaps = colliderComponent.getOverlaps();

      const characterEntityOverlapped = overlaps.find((overlap) => {
        // todo: bad search method, think about it
        return overlap.colliderComponent.entity.getComponent(TPControllerComponent);
      });

      if (!characterEntityOverlapped) return;

      this.app.sceneManager.loadScene<ShopStreetSceneLoadData>(
        new ShopStreetScene({ graphqlClient: this.graphqlClient }),
        { streetId: teleportComponent.destinationId, fromStreetId: this.currentStreetId },
      );
    });
  }
}
