import * as Three from 'three';
import * as ThreeMeshUI from 'three-mesh-ui';
import { System } from '../System';
import { UIDocumentComponent } from '../components/UIDocument.component';
import { InputSystem } from './InputSystem';
import { ControllerName, XRInputSystem } from './XRInputSystem';

export enum UIDocumentElementState {
  Default = 'Default',
  Active = 'Active',
  Hovered = 'Hovered',
}

export type UIDocumentElementData = {
  id?: string;
  interactive?: boolean;
};

export class UIDocumentSystem extends System {
  protected rayCaster: Three.Raycaster = new Three.Raycaster();

  protected lastXrStandardTriggerStates: [string, string] = [
    'default',
    'default',
  ];

  static get code(): string {
    return 'u_i_document';
  }

  public onUpdate(ts: number) {
    const components = this.componentManager.getComponentsByType(UIDocumentComponent);

    components.forEach((component) => this.updateState(component, false));

    ThreeMeshUI.update();
  }

  public updateState(component: UIDocumentComponent, recalculate = true): void {
    this.resetElementState(component);
    if (this.app.renderer.xr.isPresenting) {
      this.handleXRHover(component);
      this.handleXRActive(component);
    } else {
      this.handleBrowserHover(component);
      this.handleBrowserActive(component);
    }
    if (recalculate) ThreeMeshUI.update();
  }

  protected handleBrowserHover(component: UIDocumentComponent): void {
    if (!component.root || !this.app.camera) return;

    const intersections = this.getElementsBrowserIntersections(component.root, this.app.camera);
    this.handleHoverInBrowserIntersections(component, intersections);
  }

  protected handleXRHover(component: UIDocumentComponent): void {
    if (!component.root) return;

    const intersections = this.getElementsXRIntersections(component.root);
    this.handleHoverInXRIntersections(component, intersections);
  }

  protected handleXRActive(component: UIDocumentComponent): void {
    const [leftIsPressed, rightIsPressed] = this.xrStandardTriggersPressedInCurrentFrame();

    Object.keys(component.elementStateDataList).forEach((id) => {
      const stateData = component.elementStateDataList[id];

      if (stateData.state !== UIDocumentElementState.Hovered) return;

      const isLeft = stateData.source?.controllerName === ControllerName.Left;
      const isRight = stateData.source?.controllerName === ControllerName.Right;

      if ((isLeft && leftIsPressed) || (isRight && rightIsPressed)) {
        component.elementStateDataList[id].state = UIDocumentElementState.Active;
      }
    });
  }

  protected handleBrowserActive(component: UIDocumentComponent): void {
    const inputSystem = this.app.getSystemOrFail(InputSystem);

    if (inputSystem.mouse.leftButton.wasPressedThisFrame) {
      Object.keys(component.elementStateDataList).forEach((id) => {
        if (component.elementStateDataList[id].state === UIDocumentElementState.Hovered) {
          component.elementStateDataList[id].state = UIDocumentElementState.Active;
        }
      });
    }
  }

  protected getElementStates(element: ThreeMeshUI.Block): string[] {
    // todo: missing types
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return element.states || [];
  }

  protected setElementState(element: ThreeMeshUI.Block, state: UIDocumentElementState): void {
    // todo: missing types
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    element.setState(state);
  }

  protected getElementFromFrame(frame: Three.Object3D): ThreeMeshUI.Block | undefined {
    if (!frame.parent) return;
    if (!(frame.parent instanceof ThreeMeshUI.Block)) return;

    return frame.parent;
  }

  protected getElementData(element: ThreeMeshUI.Block): UIDocumentElementData {
    return element.userData?.uiData || {};
  }

  protected getElementsXRIntersections(rootElement: ThreeMeshUI.Block): Three.Intersection[][] {
    const xRInputSystem = this.app.getSystemOrFail(XRInputSystem);
    const raySpaces = [
      xRInputSystem.getRaySpace(ControllerName.Left),
      xRInputSystem.getRaySpace(ControllerName.Right),
    ];

    return raySpaces.map((raySpace) => {
      if (!raySpace) return [];
      const rotationMatrix = new Three.Matrix4();
      rotationMatrix.extractRotation(raySpace.matrixWorld);
      this.rayCaster.ray.origin.setFromMatrixPosition(raySpace.matrixWorld);
      this.rayCaster.ray.direction.set(0, 0, -1).applyMatrix4(rotationMatrix);

      return this.rayCaster.intersectObject(rootElement, true);
    }, []);
  }

  protected getElementsBrowserIntersections(rootElement: ThreeMeshUI.Block, camera: Three.Camera): Three.Intersection[] {
    const inputSystem = this.app.getSystemOrFail(InputSystem);
    this.rayCaster.setFromCamera(inputSystem.mouse.position, camera);

    return this.rayCaster.intersectObject(rootElement, true);
  }

  protected handleHoverInXRIntersections(component: UIDocumentComponent, totalIntersections: Three.Intersection[][]): void {
    const controllerNames = [ControllerName.Left, ControllerName.Right];

    totalIntersections.forEach((intersections, intersectionsIndex) => {
      intersections.forEach((intersect) => {
        const element = this.getElementFromFrame(intersect.object);

        if (!element || !element.visible) return;

        const elementData = this.getElementData(element);

        if (!elementData.id || !elementData.interactive) return;

        this.setElementState(element, UIDocumentElementState.Hovered);

        component.elementStateDataList[elementData.id] = {
          state: UIDocumentElementState.Hovered,
          source: {
            type: 'xr',
            controllerName: controllerNames[intersectionsIndex],
          },
        };
      });
    });
  }

  protected handleHoverInBrowserIntersections(component: UIDocumentComponent, intersections: Three.Intersection[]): void {
    intersections.forEach((intersect) => {
      const element = this.getElementFromFrame(intersect.object);

      if (!element || !element.visible) return;

      const elementData = this.getElementData(element);

      if (!elementData.id || !elementData.interactive) return;

      this.setElementState(element, UIDocumentElementState.Hovered);

      component.elementStateDataList[elementData.id] = {
        state: UIDocumentElementState.Hovered,
        source: {
          type: 'browser',
          controllerName: 'mouse',
        },
      };
    });
  }

  protected resetElementState(component: UIDocumentComponent): void {
    component.elementStateDataList = {};

    if (!component.root) return;

    component.root.traverse((element) => {
      if (!(element instanceof ThreeMeshUI.Block)) return;
      const data = this.getElementData(element);

      if (!data.interactive || !data.id) return;

      component.elementStateDataList[data.id] = { state: UIDocumentElementState.Default };
      this.setElementState(element, UIDocumentElementState.Default);
    });
  }

  // todo: improve xrinput system with current frame states
  protected xrStandardTriggersPressedInCurrentFrame(): [boolean, boolean] {
    const currentStates = [
      this.app.getSystemOrFail(XRInputSystem).getLeftXrStandardTrigger().state,
      this.app.getSystemOrFail(XRInputSystem).getRightXrStandardTrigger().state,
    ];

    return this.lastXrStandardTriggerStates.map((prevState, stateIndex) => {
      if (currentStates[stateIndex] === 'touched') {
        this.lastXrStandardTriggerStates[stateIndex] = currentStates[stateIndex];
        return false;
      }

      if (currentStates[stateIndex] === this.lastXrStandardTriggerStates[stateIndex]) return false;

      this.lastXrStandardTriggerStates[stateIndex] = currentStates[stateIndex];

      return currentStates[stateIndex] === 'pressed';
    }) as [boolean, boolean];
  }
}
