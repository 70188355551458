import * as Apollo from '@apollo/client';
import { Application } from '../../engine/Application';
import { InputSystem } from '../../engine/systems/InputSystem';
import { CameraSystem } from '../../engine/systems/Camera.system';
import { PhysicSystem } from '../../engine/systems/Physic.system';
import { XRInputSystem } from '../../engine/systems/XRInputSystem';
import { XRFPControllerSystem } from '../systems/XRFPController.system';
import { TPControllerSystem } from '../systems/TPController.system';
import { XRStatsSystem } from '../../engine/systems/XRStats.system';
import { UIDocumentSystem } from '../../engine/systems/UIDocument.system';
import { DashboardSystem } from '../systems/Dashboard.system/index';
import { AbstractScene } from '../../engine/scene/AbstractScene';
import { ThreeMemoryCleaner } from '../../engine/services/ThreeMemoryCleaner';
import { setupControllers } from './helpers/setupControllers';
import { setupDashboard } from './helpers/setupDashboard';
import { setupEnvironment } from './helpers/setupEnvironment';
import { setupCharacter } from './helpers/setupCharacter';
import { LandingZoneGenerator } from '../services/LandingZoneGenerator.service';
import { TeleportSystem } from '../systems/Teleport.system';
import { AnimatorSystem } from '../../engine/systems/Animator.system';
import { PlayerControlsSystem } from '../systems/PlayerControls.system';
import { FPControllerSystem } from '../systems/FPController.system';

export type LandingZoneSceneOptions = {
  graphqlClient: Apollo.ApolloClient<Apollo.NormalizedCacheObject>;
};

export class LandingZoneScene extends AbstractScene {
  protected graphqlClient: Apollo.ApolloClient<Apollo.NormalizedCacheObject>;

  constructor(options: LandingZoneSceneOptions) {
    super();
    this.graphqlClient = options.graphqlClient;
  }

  load(app: Application): Promise<void> {
    const landingZoneGeneratorService = new LandingZoneGenerator({ app, graphqlClient: this.graphqlClient });

    return landingZoneGeneratorService.loadStreetsData().then(() => {
      this.setupSystems(app);

      const [characterEntity, cameraEntity] = setupCharacter(app, this.threeScene);
      setupControllers(app, cameraEntity);
      setupDashboard(
        app,
        characterEntity,
        'Landing zone',
        'nope',
        {
          topLeft: {
            id: landingZoneGeneratorService.links?.[0]?.[0],
            name: landingZoneGeneratorService.links?.[0]?.[1],
          },
          topMiddle: {
            id: landingZoneGeneratorService.links?.[1]?.[0],
            name: landingZoneGeneratorService.links?.[1]?.[1],
          },
          topRight: {
            id: landingZoneGeneratorService.links?.[2]?.[0],
            name: landingZoneGeneratorService.links?.[2]?.[1],
          },
          bottomLeft: {
            id: landingZoneGeneratorService.links?.[3]?.[0],
            name: landingZoneGeneratorService.links?.[3]?.[1],
          },
          bottomMiddle: {
            id: landingZoneGeneratorService.links?.[4]?.[0],
            name: landingZoneGeneratorService.links?.[4]?.[1],
          },
          bottomRight: {
            id: landingZoneGeneratorService.links?.[5]?.[0],
            name: landingZoneGeneratorService.links?.[5]?.[1],
          },
        }
      );

      this.threeScene.add(...landingZoneGeneratorService.generateEntities());

      setupEnvironment(app, this.threeScene);
    }).then(() => undefined);
  }

  destroy(app: Application): Promise<void> {
    app.destroyAllSystems();
    app.componentManager.destroyAllComponents();
    ThreeMemoryCleaner.disposeThreeGraph(this.threeScene);

    return Promise.resolve(undefined);
  }

  protected setupSystems(app: Application): void {
    [
      // engine
      new InputSystem({ app }),
      new XRInputSystem({ app }),
      new CameraSystem({ app }),
      new PhysicSystem({ app }),
      new AnimatorSystem({ app }),
      new UIDocumentSystem({ app }),
      new XRStatsSystem({ app }),
      // domain
      new PlayerControlsSystem({ app }),
      new XRFPControllerSystem({ app }),
      new FPControllerSystem({ app }),
      new TPControllerSystem({ app }),
      new DashboardSystem({ app, graphqlClient: this.graphqlClient }),
      new TeleportSystem({ app, graphqlClient: this.graphqlClient }),
    ].forEach((system) => app.addSystem(system));
  }
}
