import * as Apollo from '@apollo/client';
import * as Three from 'three';
import randomSeed from 'random-seed';
import { Entity } from '../../engine/Entity';
import { AssetSourceType, MeshRendererComponent } from '../../engine/components/MeshRenderer.component';
import { RigidBodyComponent } from '../../engine/components/RigidBody.component';
import { Application } from '../../engine/Application';
import { EntityManager } from '../../engine/EntityManager';
import { ColliderComponent, ColliderType } from '../../engine/components/Collider.component';
import { TeleportComponent } from '../components/Teleport.component';
import * as landingZoneModels from '../assets/landingZoneModels';
import * as graphql from '../api/graphql/index';
import { GetLandingZoneStreetsQueryResult, GetStreetQueryResult, GetStreetQueryVariables } from '../../types/graphqlWS';

export type StreetGeneratorServiceOptions = {
  app: Application;
  graphqlClient: Apollo.ApolloClient<Apollo.NormalizedCacheObject>;
};

// todo: refactor!!!, fetch data from api
export class LandingZoneGenerator {
  public links: [string, string][] = [];

  protected readonly sceneAssetsPrefix = '/assets/scene/';

  protected app: Application;

  protected graphqlClient: Apollo.ApolloClient<Apollo.NormalizedCacheObject>;

  protected random = randomSeed.create('999');

  constructor(options: StreetGeneratorServiceOptions) {
    this.app = options.app;
    this.graphqlClient = options.graphqlClient;
  }

  public loadStreetsData(): Promise<void> {
    const queryOptions = { query: graphql.GetLandingZoneStreets };

    return this.graphqlClient
      .query<GetLandingZoneStreetsQueryResult>(queryOptions)
      .then((response) => {
        this.links = response.data.availableStreets?.data?.map((street) => {
          return [street?.id || '', street?.name || ''];
        }) || [];
      });
  }

  public generateEntities(): Entity[] {
    const entities: Entity[] = [];

    entities.push(this.buildSquare());
    entities.push(this.buildFloorCollider());
    entities.push(...this.buildTeleports());

    return entities;
  }

  protected get entityManager(): EntityManager {
    return this.app.entityManager;
  }

  protected getRandomModelPath(modelPaths: string[]): string {
    return `${this.sceneAssetsPrefix}${modelPaths[this.random(modelPaths.length)]}`;
  }

  protected buildFloorCollider(): Entity {
    const floor = this.entityManager.makeEntity();

    floor.position.y = -0.2;
    floor.addComponent(ColliderComponent, { shapeData: { type: ColliderType.StaticPlane } });
    floor.addComponent(RigidBodyComponent);

    return floor;
  }

  protected buildSquare(): Entity {
    const squareEntity = this.entityManager.makeEntity();

    squareEntity.addComponent(MeshRendererComponent, {
      sourceData: {
        type: AssetSourceType.GLTF,
        url: this.getRandomModelPath(landingZoneModels.squareModels),
      },
    }).events.on('contentAdded', (c) => {
      // todo: temporary fix tree transparency, need material management
      c.content.children.forEach((obj) => {
        if (!(obj instanceof Three.Mesh)) return;
        if (!(obj.material instanceof Three.MeshStandardMaterial)) return;

        if (obj.name === 'zone_end') {
          obj.material.depthWrite = true;
        }
        if (obj.name === 'palm_alpha') {
          obj.material.alphaTest = 0.5;
          obj.material.depthWrite = true;
        }
      });
    });
    squareEntity.addComponent(RigidBodyComponent);

    const squareCollider = this.entityManager.makeEntity();
    squareCollider.addComponent(MeshRendererComponent, {
      sourceData: {
        type: AssetSourceType.GLTF,
        url: this.getRandomModelPath(landingZoneModels.squareColliders),
      },
    }).events.on('contentAdded', ({ content }) => {
      content.visible = false;
    });
    squareCollider.addComponent(ColliderComponent, {
      shapeData: {
        type: ColliderType.TriangleMesh,
        meshName: 'square_col',
        applyTransform: true,
      },
    });
    squareCollider.addComponent(RigidBodyComponent);

    squareEntity.add(squareCollider);

    return squareEntity;
  }

  protected buildTeleports(): Entity[] {
    const destinationIds = this.links.slice(0, 4);
    const teleportPositions = [
      new Three.Vector3(0, 1.1, 29),
      new Three.Vector3(0, 1.1, -29),
      new Three.Vector3(29, 1.1, 0),
      new Three.Vector3(-29, 1.1, 0),
    ];
    const teleports: Entity[] = [];

    destinationIds.forEach((data, index: number) => {
      const destinationId = data[0];
      const teleportEntity = this.entityManager.makeEntity();
      teleportEntity.position.copy(teleportPositions[index]);
      teleportEntity.addComponent(ColliderComponent, {
        isTrigger: true,
        shapeData: {
          type: ColliderType.Box,
          boxHalfExtents: new Three.Vector3(2.8, 1, 2.8),
        },
      });
      teleportEntity.addComponent(TeleportComponent, { destinationId });
      teleports.push(teleportEntity);
    });

    return teleports;
  }
}
