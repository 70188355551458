import randomSeed from 'random-seed';
import * as Three from 'three';
import * as Apollo from '@apollo/client';
import { Entity } from '../../engine/Entity';
import { AssetSourceType, MeshRendererComponent } from '../../engine/components/MeshRenderer.component';
import { RigidBodyComponent } from '../../engine/components/RigidBody.component';
import { Application } from '../../engine/Application';
import { EntityManager } from '../../engine/EntityManager';
import * as shopStreetModels from '../assets/streetElementModels';
import { ColliderComponent, ColliderType } from '../../engine/components/Collider.component';
import { TeleportComponent } from '../components/Teleport.component';
import { GetStreetQueryResult, GetStreetQueryVariables } from '../../types/graphqlWS';
import * as graphql from '../api/graphql';

export type StreetGeneratorServiceOptions = {
  app: Application;
  fromStreetId?: string;
  streetId: string;
  graphqlClient: Apollo.ApolloClient<Apollo.NormalizedCacheObject>;
};

export type ShopPartData = {
  color: string;
  modelUrl: string;
  replaceImageUrl?: string;
};

export type ShopData = {
  placeId: number;
  shopIsActive: boolean;
  stub: ShopPartData;
  midWall: ShopPartData;
  view: ShopPartData;
  shopParts: {
    door: ShopPartData;
    roof: ShopPartData;
    floorFirst: ShopPartData;
    floorSecond: ShopPartData;
    name: ShopPartData;
    umbrella: ShopPartData;
  };
};

export type CrossroadLinkData = {
  id: string;
  name: string;
};

export type CrossroadLinksData = {
  left: CrossroadLinkData;
  right: CrossroadLinkData;
  forward: CrossroadLinkData;
};

export type StreetLinksData = {
  start: CrossroadLinksData;
  end: CrossroadLinksData;
};

// todo: refactor!!!, fetch data from api
export class StreetGeneratorService {
  protected readonly sceneAssetsPrefix = '/assets/scene/';

  protected app: Application;

  protected graphqlClient: Apollo.ApolloClient<Apollo.NormalizedCacheObject>;

  protected random = randomSeed.create('999');

  protected shopDataList: ShopData[] = [];

  protected _currentStreetId = '';

  protected _currentStreetName = '';

  protected _streetLinksData: StreetLinksData = {
    start: {
      left: {
        id: '',
        name: '',
      },
      right: {
        id: '',
        name: '',
      },
      forward: {
        id: '',
        name: '',
      },
    },
    end: {
      left: {
        id: '',
        name: '',
      },
      right: {
        id: '',
        name: '',
      },
      forward: {
        id: '',
        name: '',
      },
    },
  };

  protected streetData = {
    totalShopCount: 20,
    streetWidth: 22,
    shopSize: 8,
    crossroadSize: 40,
  };

  constructor(options: StreetGeneratorServiceOptions) {
    this.app = options.app;
    this._currentStreetId = options.streetId;
    this.graphqlClient = options.graphqlClient;
  }

  public get streetLinksData(): StreetLinksData {
    return this._streetLinksData;
  }

  public get currentStreetName(): string {
    return this._currentStreetName;
  }

  public get currentStreetId(): string {
    return this._currentStreetId;
  }

  public loadStreetData(): Promise<void> {
    return this.graphqlClient.query<GetStreetQueryResult, GetStreetQueryVariables>({
      query: graphql.GetStreet,
      variables: { streetId: this._currentStreetId },
      fetchPolicy: 'network-only',
    })
      .then((response) => {
        this._currentStreetName = response.data.street?.name || '';
        this.shopDataList = this.mapApiShopDataList(response.data);
        this._streetLinksData = this.mapApiStreetLinksData(response.data);
        console.info(`Street ${response.data.street?.name}:${response.data.street?.id} loaded`);
        console.info(`Street Links ${JSON.stringify(this._streetLinksData)}`);
      });
  }

  public generateEntities(): Entity[] {
    const entities: Entity[] = [];

    entities.push(this.buildShopsArea());
    entities.push(...this.buildCrossroadAreas());

    entities.push(this.buildFloorCollider());

    return entities;
  }

  public getSpawnTransform(threeScene: Three.Scene, fromStreetId?: string, placeId?: string): Three.Matrix4 {
    let spawnEntity: Three.Object3D | undefined;

    if (placeId) {
      spawnEntity = threeScene.getObjectByName(`shop_spawn_${placeId}`);
    } else if (fromStreetId) {
      spawnEntity = threeScene.getObjectByName(`link_spawn_${fromStreetId}`);
    }

    if (!spawnEntity) return new Three.Matrix4();

    spawnEntity.updateMatrixWorld(true);
    spawnEntity.updateWorldMatrix(true, true);

    return spawnEntity.matrixWorld;
  }

  protected buildShopsArea(): Entity {
    const shopsAreaEntity = this.entityManager.makeEntity();

    shopsAreaEntity.position.x = 0;
    shopsAreaEntity.position.x = -(this.shopAreaLength / 2);
    shopsAreaEntity.position.z = -this.streetData.streetWidth / 2;

    this.shopDataList.forEach((shopData) => {
      if (shopData.shopIsActive) {
        shopsAreaEntity.add(this.buildShop(shopData));
      } else {
        shopsAreaEntity.add(this.buildStub(shopData));
      }
    });
    for (let i = 0; i < this.streetData.totalShopCount / 2; i++) shopsAreaEntity.add(this.buildRoad(i));
    shopsAreaEntity.add(...this.buildShopsColliders());

    return shopsAreaEntity;
  }

  protected get entityManager(): EntityManager {
    return this.app.entityManager;
  }

  protected get shopAreaLength(): number {
    return (this.streetData.totalShopCount / 2) * this.streetData.shopSize;
  }

  protected getRandomModelPathIndex(modelPaths: string[]): number {
    return this.random(modelPaths.length);
  }

  protected getModelPathByIndex(modelPaths: string[], index: number): string {
    return `${this.sceneAssetsPrefix}${modelPaths[index]}`;
  }

  protected getRandomModelPath(modelPaths: string[]): string {
    return this.getModelPathByIndex(modelPaths, this.getRandomModelPathIndex(modelPaths));
  }

  protected getRandomColor() {
    const colors = ['#63b79d', '#7ab3de', '#ffc4ab', '#f8e6ff', '#5c3748', '#e5cdc4'];

    return colors[this.random(colors.push())];
  }

  protected getShopPositionById(id: number): Three.Vector3 {
    return new Three.Vector3(
      this.streetData.shopSize * Math.floor(id / 2) + this.streetData.shopSize / 2,
      0,
      id % 2 === 0 ? 0 : this.streetData.streetWidth,
    );
  }

  protected getShopRotationById(index: number): Three.Euler {
    return new Three.Euler(
      0,
      index % 2 === 0 ? 0 : Math.PI,
      0,
    );
  }

  protected getRoadPositionByIndex(index: number): Three.Vector3 {
    return new Three.Vector3(
      index * this.streetData.shopSize + this.streetData.shopSize / 2,
      0,
      this.streetData.streetWidth / 2,
    );
  }

  protected buildStub(shopData: ShopData): Entity {
    const stubEntity = this.entityManager.makeEntity();

    stubEntity.position.copy(this.getShopPositionById(shopData.placeId));
    stubEntity.rotation.copy(this.getShopRotationById(shopData.placeId));
    stubEntity.quaternion.setFromEuler(stubEntity.rotation);

    const stubMainEntity = this.entityManager.makeEntity();
    stubMainEntity.addComponent(MeshRendererComponent, {
      sourceData: { type: AssetSourceType.GLTF, url: shopData.stub.modelUrl },
      color: new Three.Color().setStyle(shopData.stub.color),
    });
    stubEntity.add(stubMainEntity);

    const midWallEntity = this.entityManager.makeEntity();
    midWallEntity.addComponent(MeshRendererComponent, {
      sourceData: { type: AssetSourceType.GLTF, url: shopData.midWall.modelUrl },
    });
    stubEntity.add(midWallEntity);

    stubEntity.add(this.buildShopSpawn(shopData.placeId));

    return stubEntity;
  }

  protected buildShop(shopData: ShopData): Entity {
    const shopEntity = this.entityManager.makeEntity();

    shopEntity.position.copy(this.getShopPositionById(shopData.placeId));
    shopEntity.rotation.copy(this.getShopRotationById(shopData.placeId));
    shopEntity.quaternion.setFromEuler(shopEntity.rotation);

    const doorEntity = this.entityManager.makeEntity();
    doorEntity.addComponent(MeshRendererComponent, {
      sourceData: { type: AssetSourceType.GLTF, url: shopData.shopParts.door.modelUrl },
      color: new Three.Color().setStyle(shopData.shopParts.door.color),
    }).events.on('contentAdded', ({ content }) => {
      this.replaceImageOnShopPart(content, shopData.shopParts.door.replaceImageUrl);
    });
    shopEntity.add(doorEntity);

    const roofEntity = this.entityManager.makeEntity();
    roofEntity.addComponent(MeshRendererComponent, {
      sourceData: { type: AssetSourceType.GLTF, url: shopData.shopParts.roof.modelUrl },
      color: new Three.Color().setStyle(shopData.shopParts.roof.color),
    });
    shopEntity.add(roofEntity);

    const floorFirstEntity = this.entityManager.makeEntity();
    floorFirstEntity.addComponent(MeshRendererComponent, {
      sourceData: { type: AssetSourceType.GLTF, url: shopData.shopParts.floorFirst.modelUrl },
      color: new Three.Color().setStyle(shopData.shopParts.floorFirst.color),
    }).events.on('contentAdded', ({ content }) => {
      this.replaceImageOnShopPart(content, shopData.shopParts.floorFirst.replaceImageUrl);
    });
    shopEntity.add(floorFirstEntity);

    const floorSecondEntity = this.entityManager.makeEntity();
    floorSecondEntity.addComponent(MeshRendererComponent, {
      sourceData: { type: AssetSourceType.GLTF, url: shopData.shopParts.floorSecond.modelUrl },
      color: new Three.Color().setStyle(shopData.shopParts.floorSecond.color),
    });
    shopEntity.add(floorSecondEntity);

    const nameEntity = this.entityManager.makeEntity();
    nameEntity.addComponent(MeshRendererComponent, {
      sourceData: { type: AssetSourceType.GLTF, url: shopData.shopParts.name.modelUrl },
      color: new Three.Color().setStyle(shopData.shopParts.name.color),
    }).events.on('contentAdded', ({ content }) => {
      this.replaceImageOnShopPart(content, shopData.shopParts.name.replaceImageUrl);
    });
    shopEntity.add(nameEntity);

    const midWallEntity = this.entityManager.makeEntity();
    midWallEntity.addComponent(MeshRendererComponent, {
      sourceData: { type: AssetSourceType.GLTF, url: shopData.midWall.modelUrl },
    });
    shopEntity.add(midWallEntity);

    const umbrellaEntity = this.entityManager.makeEntity();
    umbrellaEntity.addComponent(MeshRendererComponent, {
      sourceData: { type: AssetSourceType.GLTF, url: shopData.shopParts.umbrella.modelUrl },
    });
    shopEntity.add(umbrellaEntity);

    const viewEntity = this.entityManager.makeEntity();
    viewEntity.addComponent(MeshRendererComponent, {
      sourceData: { type: AssetSourceType.GLTF, url: shopData.view.modelUrl },
    });
    shopEntity.add(viewEntity);

    shopEntity.add(this.buildShopSpawn(shopData.placeId));

    return shopEntity;
  }

  protected buildRoad(index: number): Entity {
    const roadEntity = this.entityManager.makeEntity();
    roadEntity.position.copy(this.getRoadPositionByIndex(index));
    roadEntity.rotateY(Math.PI / 2);

    const roadCenterEntity = this.buildRoadCenter();
    const roadSidewalkEntity = this.buildRoadSidewalk();

    roadEntity.add(roadCenterEntity);
    roadEntity.add(roadSidewalkEntity);

    [[4.5, 2.8], [4.5, -2.8], [-4.5, 2.8], [-4.5, -2.8]].forEach((item) => {
      const roadElementEntity = this.buildRoadElement(new Three.Vector3(item[0], 0, item[1]));
      roadEntity.add(roadElementEntity);
    });

    return roadEntity;
  }

  protected buildFloorCollider(): Entity {
    const floor = this.entityManager.makeEntity();

    floor.addComponent(ColliderComponent, { shapeData: { type: ColliderType.StaticPlane } });
    floor.addComponent(RigidBodyComponent);
    floor.position.y = -0.1;

    return floor;
  }

  protected buildShopsColliders(): Entity[] {
    const shopsTotalLength = this.streetData.shopSize * (this.streetData.totalShopCount / 2);

    /**
     * 0,0
     *                wall1
     *        |----------------------|
     *        |                      |
     *        |----------------------|
     *                wall2                   1,1
     */

    const wall1 = this.entityManager.makeEntity();
    wall1.position.set(shopsTotalLength / 2, 10 / 2, 0);
    wall1.addComponent(ColliderComponent, {
      shapeData: {
        type: ColliderType.Box,
        boxHalfExtents: new Three.Vector3(shopsTotalLength / 2, 5, 0.5),
      },
    });
    wall1.addComponent(RigidBodyComponent);

    const wall2 = this.entityManager.makeEntity();
    wall2.position.set(shopsTotalLength / 2, 10 / 2, this.streetData.streetWidth);
    wall2.addComponent(ColliderComponent, {
      shapeData: {
        type: ColliderType.Box,
        boxHalfExtents: new Three.Vector3(shopsTotalLength / 2, 5, 0.5),
      },
    });
    wall2.addComponent(RigidBodyComponent);

    return [
      wall1,
      wall2,
    ];
  }

  protected buildCrossroadAreas(): Entity[] {
    return [
      this.buildCrossroadArea(
        -(this.shopAreaLength / 2 + this.streetData.crossroadSize / 2),
        Math.PI + Math.PI / 2,
        this._streetLinksData.start,
        true,
      ),
      this.buildCrossroadArea(
        this.shopAreaLength / 2 + this.streetData.crossroadSize / 2,
        Math.PI / 2,
        this._streetLinksData.end,
        false,
      ),
    ];
  }

  protected buildCrossroadArea(deltaX: number, rotation: number, links: CrossroadLinksData, fixX: boolean): Entity {
    const crossroadArea = this.entityManager.makeEntity();

    crossroadArea.position.setX(deltaX);
    crossroadArea.rotation.y = rotation;
    if (fixX) crossroadArea.translateX(0.06);
    crossroadArea.translateZ(-0.02);

    crossroadArea.add(this.buildCrossroadMesh());
    const teleports = this.buildCrossroadTeleports(links);
    if (teleports.length) crossroadArea.add(...teleports);

    return crossroadArea;
  }

  protected buildCrossroadMesh(): Entity {
    const crossroadEntity = this.entityManager.makeEntity();
    const crossroadCollider = this.entityManager.makeEntity();

    crossroadEntity.addComponent(MeshRendererComponent, {
      sourceData: {
        type: AssetSourceType.GLTF,
        url: this.getRandomModelPath(shopStreetModels.crossroadModels),
      },
    });
    crossroadEntity.addComponent(ColliderComponent, {
      shapeData: {
        type: ColliderType.TriangleMesh,
        meshName: 'Plane',
        applyTransform: false,
      },
    });
    crossroadEntity.addComponent(RigidBodyComponent);
    crossroadEntity.add(crossroadCollider);

    crossroadCollider.addComponent(MeshRendererComponent, {
      sourceData: {
        type: AssetSourceType.GLTF,
        url: this.getRandomModelPath(shopStreetModels.crossroadColliders),
      },
    }).events.on('contentAdded', ({ content }) => {
      content.visible = false;
    });
    crossroadCollider.addComponent(ColliderComponent, {
      shapeData: {
        type: ColliderType.TriangleMesh,
        meshName: 'crossroad_col',
        applyTransform: true,
      },
    });
    crossroadCollider.addComponent(RigidBodyComponent);

    return crossroadEntity;
  }

  /**
   * 0,0
   *         exit
   *       |-------|
   *       |       |
   * right |  0,0  | left
   *       |       |
   *       |-------|
   *        forward       1,1
   */
  protected buildCrossroadTeleports(links: CrossroadLinksData): Entity[] {
    const entities = [
      links.left.id,
      links.forward.id,
      links.right.id,
    ].map((streetId: string, streetIndex: number) => {
      if (!streetId) return;

      const teleportPivot = this.entityManager.makeEntity();
      const teleportEntity = this.entityManager.makeEntity();
      const spawnEntity = this.entityManager.makeEntity({ name: `link_spawn_${streetId}` });

      teleportPivot.add(teleportEntity);

      spawnEntity.addComponent(ColliderComponent);

      teleportPivot.rotateY(-Math.PI / 2 - (Math.PI / 2) * streetIndex);
      teleportEntity.position.z = -17;
      teleportEntity.addComponent(TeleportComponent, { destinationId: streetId });
      teleportEntity.addComponent(ColliderComponent, {
        isTrigger: true,
        shapeData: {
          type: ColliderType.Box,
          boxHalfExtents: new Three.Vector3(10, 1, 2),
        },
      });

      teleportEntity.add(spawnEntity);
      spawnEntity.position.z = 5;
      spawnEntity.rotation.y = Math.PI;

      return teleportPivot;
    });

    return entities.filter((entity) => !!entity) as Entity[];
  }

  protected buildShopSpawn(placeId: number): Entity {
    const spawnEntity = this.entityManager.makeEntity({ name: `shop_spawn_${placeId}` });

    spawnEntity.position.z = 4.5;
    spawnEntity.position.y = 1;

    return spawnEntity;
  }

  protected buildRoadCenter(): Entity {
    const roadCenterModelIndex = this.getRandomModelPathIndex(shopStreetModels.streetModels);
    const roadCenterEntity = this.entityManager.makeEntity();
    const roadCenterCollider = this.entityManager.makeEntity();

    roadCenterEntity.addComponent(MeshRendererComponent, {
      sourceData: {
        type: AssetSourceType.GLTF,
        url: this.getModelPathByIndex(shopStreetModels.streetModels, roadCenterModelIndex),
      },
    });
    roadCenterCollider.addComponent(MeshRendererComponent, {
      sourceData: {
        type: AssetSourceType.GLTF,
        url: this.getModelPathByIndex(shopStreetModels.streetColliders, roadCenterModelIndex),
      },
    }).events.on('contentAdded', ({ content }) => {
      content.visible = false;
    });
    roadCenterCollider.addComponent(ColliderComponent, {
      shapeData: {
        type: ColliderType.TriangleMesh,
        meshName: `street_${roadCenterModelIndex + 1}_col`,
        applyTransform: true,
      },
    });
    roadCenterCollider.addComponent(RigidBodyComponent);

    roadCenterEntity.add(roadCenterCollider);

    return roadCenterEntity;
  }

  protected buildRoadSidewalk(): Entity {
    const roadSidewalkModelIndex = this.getRandomModelPathIndex(shopStreetModels.streetMainModels);
    const roadSidewalkEntity = this.entityManager.makeEntity();
    const roadSidewalkCollider = this.entityManager.makeEntity();

    roadSidewalkEntity.addComponent(MeshRendererComponent, {
      sourceData: {
        type: AssetSourceType.GLTF,
        url: this.getModelPathByIndex(shopStreetModels.streetMainModels, roadSidewalkModelIndex),
      },
    });
    roadSidewalkCollider.addComponent(MeshRendererComponent, {
      sourceData: {
        type: AssetSourceType.GLTF,
        url: this.getModelPathByIndex(shopStreetModels.streetMainCollider, roadSidewalkModelIndex),
      },
    }).events.on('contentAdded', ({ content }) => {
      content.visible = false;
    });
    roadSidewalkCollider.addComponent(ColliderComponent, {
      shapeData: {
        type: ColliderType.TriangleMesh,
        meshName: 'street__main_col', // always has this name (?)
        applyTransform: true,
      },
    });
    roadSidewalkCollider.addComponent(RigidBodyComponent);

    roadSidewalkEntity.add(roadSidewalkCollider);

    return roadSidewalkEntity;
  }

  protected buildRoadElement(position: Three.Vector3): Entity {
    const roadElementModelIndex = this.getRandomModelPathIndex(shopStreetModels.streetElementModels);
    const roadElementEntity = this.entityManager.makeEntity();
    const roadElementCollider = this.entityManager.makeEntity();

    roadElementEntity.position.copy(position);

    roadElementEntity.addComponent(MeshRendererComponent, {
      sourceData: {
        type: AssetSourceType.GLTF,
        url: this.getModelPathByIndex(shopStreetModels.streetElementModels, roadElementModelIndex),
      },
    });
    roadElementCollider.addComponent(MeshRendererComponent, {
      sourceData: {
        type: AssetSourceType.GLTF,
        url: this.getModelPathByIndex(shopStreetModels.streetElementColliders, roadElementModelIndex),
      },
    }).events.on('contentAdded', ({ content }) => {
      content.visible = false;
    });
    roadElementCollider.addComponent(ColliderComponent, {
      shapeData: {
        type: ColliderType.TriangleMesh,
        meshName: `street_el_${roadElementModelIndex + 1}_col`,
        applyTransform: true,
      },
    });
    roadElementCollider.addComponent(RigidBodyComponent);

    roadElementEntity.add(roadElementCollider);

    return roadElementEntity;
  }

  protected mapApiShopDataList(data: GetStreetQueryResult): ShopData[] {
    const shopDataList: ShopData[] = [];

    data?.street?.places.forEach((place) => {
      const layout = place.holder?.holderConfig?.layout;
      const { stubInfo } = place;
      const firstFloorInfo = layout?.firstFloorInfo;
      const doorInfo = firstFloorInfo?.layoutInfo.doorInfo;
      const umbrellaInfo = firstFloorInfo?.layoutInfo.umbrellaInfo;
      const roofInfo = layout?.roofInfo;
      const secondFloorInfo = layout?.secondFloorInfo;
      const signInfo = layout?.signAreaInfo;

      shopDataList.push({
        placeId: parseInt(place.id, 10),
        // todo: make enum,
        shopIsActive: (place.holder?.holderConfig?.shop?.status === 'active') && !!place.holder?.holderConfig.layout,
        stub: { color: stubInfo?.color || '', modelUrl: stubInfo?.stub?.model?.url || '' },
        midWall: { color: '', modelUrl: this.getRandomModelPath(shopStreetModels.midWallModels) },
        view: { color: '', modelUrl: this.getRandomModelPath(shopStreetModels.viewModels) },
        shopParts: {
          door: {
            color: doorInfo?.payload.color || '',
            modelUrl: doorInfo?.door.model.url || '',
            replaceImageUrl: doorInfo?.payload.imageUrl || '',
          },
          roof: { color: roofInfo?.payload?.color || '', modelUrl: roofInfo?.roof.model.url || '' },
          floorFirst: {
            color: firstFloorInfo?.payload?.color || '',
            modelUrl: firstFloorInfo?.firstFloor.model.url || '',
            replaceImageUrl: '',
          },
          floorSecond: { color: secondFloorInfo?.payload?.color || '', modelUrl: secondFloorInfo?.secondFloor.model.url || '' },
          name: {
            color: signInfo?.payload?.color || '',
            modelUrl: signInfo?.signArea.model.url || '',
            replaceImageUrl: signInfo?.payload?.imageUrl || '',
          },
          umbrella: { color: '', modelUrl: umbrellaInfo?.umbrella.model.url || '' },
        },
      });
    });

    return shopDataList;
  }

  protected mapApiStreetLinksData(data: GetStreetQueryResult): StreetLinksData {
    // todo: replace is to name
    return {
      start: {
        left: {
          id: data.street?.startLinks?.l?.targetId || '',
          name: data.street?.startLinks?.l?.target?.name || '',
        },
        right: {
          id: data.street?.startLinks?.r?.targetId || '',
          name: data.street?.startLinks?.r?.target?.name || '',
        },
        forward: {
          id: data.street?.startLinks?.f?.targetId || '',
          name: data.street?.startLinks?.f?.target?.name || '',
        },
      },
      end: {
        left: {
          id: data.street?.endLinks?.l?.targetId || '',
          name: data.street?.endLinks?.l?.target?.name || '',
        },
        right: {
          id: data.street?.endLinks?.r?.targetId || '',
          name: data.street?.endLinks?.r?.target?.name || '',
        },
        forward: {
          id: data.street?.endLinks?.f?.targetId || '',
          name: data.street?.endLinks?.f?.target?.name || '',
        },
      },
    };
  }

  protected replaceImageOnShopPart(partThreeObject: Three.Object3D, imageUrl?: string): void {
    if (!imageUrl) return;

    const textureLoader = new Three.TextureLoader();
    textureLoader.load(imageUrl, (texture) => {
      texture.flipY = false;

      partThreeObject.traverse((object) => {
        if (!(object instanceof Three.Mesh)) return;
        if (!(object.material instanceof Three.MeshStandardMaterial)) return;
        if (!object.material.name.endsWith('_img_mat')) return;

        object.material.map = texture;
      });
    });
  }
}
