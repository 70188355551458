import * as Three from 'three';
import { System } from '../System';
import { AnimatorComponent } from '../components/Animator.component';

// todo: refactoring
export class AnimatorSystem extends System {
  onUpdate(dt: number) {
    this.componentManager.getComponentsByType(AnimatorComponent).forEach((animatorComponent) => {
      if (!animatorComponent.isReady) return;
      this.updateTransitions(animatorComponent, dt);
      this.updateParameters(animatorComponent, dt);
      animatorComponent.threeAnimationMixer.update(dt);
    });
  }

  static get code(): string {
    return 'animator';
  }

  updateParameters(animatorComponent: AnimatorComponent, dt: number): void {
    const fadeTimeMs = 0.2;
    const valuePerMs = 1 / fadeTimeMs;
    const delta = dt * valuePerMs;

    animatorComponent.actionsData.forEach((actionData) => {
      const actionWeight = animatorComponent.actionsWeight[actionData.name] || 0;

      actionData.clipsData.forEach((clipData) => {
        const threeAction = this.getThreeActionByName(animatorComponent, actionData.name, clipData.name);

        if (!threeAction) return;

        const speedMultiplierKey = clipData.bindings?.speedMultiplier;
        const speedMultiplierValue = animatorComponent.parameters[speedMultiplierKey || ''];
        threeAction.timeScale = typeof speedMultiplierValue === 'number' ? speedMultiplierValue : (clipData.speedMultiplier ?? 1);

        const activeWeightKey = clipData.bindings?.activeWeight;
        const activeWeightValue = animatorComponent.parameters[activeWeightKey || ''];
        const targetWeight = typeof activeWeightValue === 'number' ? activeWeightValue : (clipData.activeWeight ?? 1);

        let weightTransitionValue = animatorComponent.actionClipsWight[actionData.name][clipData.name] || 0;
        if (weightTransitionValue < targetWeight) {
          weightTransitionValue += delta;
          weightTransitionValue = Math.min(weightTransitionValue, targetWeight);
        } else if (weightTransitionValue > targetWeight) {
          weightTransitionValue -= delta;
          weightTransitionValue = Math.max(weightTransitionValue, targetWeight);
        }

        animatorComponent.actionClipsWight[actionData.name][clipData.name] = weightTransitionValue;

        threeAction.weight = weightTransitionValue * actionWeight;
        if (actionWeight === 0 && threeAction.isScheduled()) {
          threeAction.stop();
        }
        if (!threeAction.isScheduled() && actionWeight !== 0) {
          threeAction.play();
          threeAction.time = clipData.startAt || 0; // startAt method not work correctly with initial time scale
        }
      });
    });
  }

  protected updateTransitions(animatorComponent: AnimatorComponent, dt: number): void {
    const activeName = animatorComponent.actionName;
    const fadeTimeMs = 0.2;
    const valuePerMs = 1 / fadeTimeMs;
    const delta = dt * valuePerMs;

    animatorComponent.actionsData.forEach((actionData) => {
      animatorComponent.actionsWeight[actionData.name] += actionData.name === activeName ? delta : -delta;
      animatorComponent.actionsWeight[actionData.name] = Three.MathUtils.clamp(
        animatorComponent.actionsWeight[actionData.name],
        0,
        1,
      );
    });
  }

  protected getThreeActionByName(
    animatorComponent: AnimatorComponent,
    actionName: string,
    clipName: string,
  ): Three.AnimationAction | undefined {
    return animatorComponent.threeAnimationActions[actionName].find((threeAction) => {
      return clipName === threeAction.getClip().name;
    });
  }
}
