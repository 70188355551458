import * as ThreeMeshUI from 'three-mesh-ui';
import * as Three from 'three';
import { addInteractivityToElement } from '../helpers/index';

export type ButtonParams = {
  id: string;
  leftIcon?: ThreeMeshUI.Block;
  rightIcon?: ThreeMeshUI.Block;
  width: number;
  height: number;
  titleLineHeight?: number;
  title?: string;
  titleWidth: number;
  titleFontSize: number;
  titleTextAlign?: string;
  padding?: number;
  autoLayout?: boolean;
  justifyContent?: 'center' | 'start' | 'end';
};

export function Button(params: ButtonParams): ThreeMeshUI.Block {
  const textBlock = new ThreeMeshUI.Block({
    width: params.titleWidth,
    height: params.titleLineHeight || params.titleFontSize,
    margin: 4,
    alignItems: 'center',
    borderRadius: 0,
    hiddenOverflow: true,
    backgroundOpacity: 0,
  });
  const textWrapper = new ThreeMeshUI.Block({
    width: params.titleWidth,
    height: params.titleLineHeight || params.titleFontSize,
    margin: 0,
    borderRadius: 0,
    textAlign: params.titleTextAlign,
    backgroundOpacity: 0,
  });
  const text = new ThreeMeshUI.Text({
    content: params.title,
    fontSize: params.titleFontSize,
  });

  const button = new ThreeMeshUI.Block({
    width: params.width ?? 254,
    height: params.height ?? 60,
    contentDirection: 'row',
    justifyContent: params.justifyContent,
    padding: params.padding ?? 0,
    alignItems: 'center',
    borderRadius: (params.height ?? 60) / 2,
    borderWidth: 0,
    backgroundColor: new Three.Color().setStyle('#222222'),
    backgroundOpacity: 0.85,
    offset: 0.1,
  });
  textBlock.add(textWrapper);
  textWrapper.add(text);

  if (params.leftIcon) button.add(params.leftIcon);
  button.add(textBlock);
  if (params.rightIcon) button.add(params.rightIcon);

  addInteractivityToElement({
    id: params.id,
    element: button,
    defaultAttributes: { backgroundColor: new Three.Color().setStyle('#222222') },
    hoveredAttributes: { backgroundColor: new Three.Color().setStyle('#1a52ff') },
    activeAttributes: { backgroundColor: new Three.Color().setStyle('#1a52ff') },
  });

  return button;
}
