import * as Three from 'three';
import { Component } from '../../engine/Component';

export class PlayerControlsComponent extends Component {
  public cameraSensitivity = Math.PI / 2;

  public virtualMovementDirection: Three.Vector2 = new Three.Vector2();

  static get code(): string {
    return 'player_controls';
  }
}
