import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import TextExample from './examples/TextExample/TextExample';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <TextExample />,
);
