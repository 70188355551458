import * as Three from 'three';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';
import { Sky } from 'three/examples/jsm/objects/Sky';
import { Application } from '../../../engine/Application';

let envCache: Three.Texture | undefined;

// todo: refactor to components
export function setupEnvironment(app: Application, threeScene: Three.Scene): void {
  const pmremGenerator = new Three.PMREMGenerator(app.renderer);

  new RGBELoader()
    .load('/assets/hdr/fouriesburg_mountain_cloudy_1k.hdr', (texture) => {
      threeScene.environment = pmremGenerator.fromEquirectangular(texture).texture;
      threeScene.environment.needsUpdate = true; // maybe not need
      pmremGenerator.dispose();
      envCache = threeScene.environment;
    });

  const hemilight = new Three.HemisphereLight();
  hemilight.position.set(0, 50, 0);
  hemilight.color = new Three.Color(0.87, 0.77, 0.61);
  hemilight.groundColor = new Three.Color(0.058, 0.058, 0.058);
  hemilight.intensity = 0.57;
  threeScene.add(hemilight);

  const sunlight = new Three.DirectionalLight();
  sunlight.position.set(-0.11, 1, 0.33);
  sunlight.intensity = 0.57;
  threeScene.add(sunlight);

  const sky = new Sky();

  sky.scale.setScalar(450000);
  threeScene.add(sky);
  const sun = new Three.Vector3();
  const { uniforms } = sky.material;
  uniforms.turbidity.value = 0.4;
  uniforms.rayleigh.value = 0.127;
  uniforms.mieCoefficient.value = 0.005;
  uniforms.mieDirectionalG.value = 0.8;
  const phi = Three.MathUtils.degToRad(2);
  const theta = Three.MathUtils.degToRad(90);
  sun.setFromSphericalCoords(1, phi, theta);
  uniforms.sunPosition.value.copy(sun);
}
