import * as Three from 'three';
import EventEmitter from 'eventemitter3';
import Ammo from 'ammo.js';
import { Component, ComponentOptions } from '../Component';
import { AmmoCollisionFlag } from '../physics/enums/AmmoCollisionFlag';
import { ThreeToAmmoConverter } from '../services/ThreeToAmmoConverter';
import { MeshRendererComponent } from './MeshRenderer.component';

export enum ColliderType {
  Capsule = 'Capsule',
  Box = 'Box',
  Compound = 'Compound',
  TriangleMesh = 'TriangleMesh',
  StaticPlane = 'StaticPlane',
}

export type CapsuleShapeData = {
  type: ColliderType.Capsule;
  radius: number;
  height: number;
};

export type BoxShapeData = {
  type: ColliderType.Box;
  boxHalfExtents: Three.Vector3;
};

export type CompoundShapeColliderData = {
  type: ColliderType.Compound;
  children: {
    collider: CapsuleShapeData | BoxShapeData;
    position: Three.Vector3;
  }[];
};

export type TriangleMeshShapeData = {
  type: ColliderType.TriangleMesh;
  meshName: string;
  applyTransform: boolean;
};

export type StaticPlaneShapeData = {
  type: ColliderType.StaticPlane;
};

export type ShapeData =
  CapsuleShapeData
  | BoxShapeData
  | CompoundShapeColliderData
  | TriangleMeshShapeData
  | StaticPlaneShapeData;

export type RigidBodyComponentOptions = ComponentOptions & {
  data?: {
    shapeData?: ShapeData;
    isTrigger?: boolean;
  };
};

export type ColliderComponentEventTypes = {
  beforeUpdate: () => void;
  afterUpdate: () => void;
};

export type OverlapData = {
  colliderComponent: ColliderComponent;
};

// todo: refactor!!!
export class ColliderComponent extends Component {
  public btCollisionShape?: Ammo.btCollisionShape;

  public btPairCachingGhostObject?: Ammo.btPairCachingGhostObject;

  public shapeData: ShapeData;

  public events: EventEmitter<ColliderComponentEventTypes> = new EventEmitter<ColliderComponentEventTypes>();

  public world?: Ammo.btDiscreteDynamicsWorld;

  public isTrigger: boolean;

  constructor(options: RigidBodyComponentOptions) {
    super(options);

    this.shapeData = options.data?.shapeData ?? this.getDefaultShapeData();
    this.isTrigger = options.data?.isTrigger ?? false;
    this.setupBtCollisionShape();
    this.setupBtGhostObject();
  }

  static get code(): string {
    return 'collider';
  }

  public getOverlaps(): OverlapData[] {
    if (!this.btPairCachingGhostObject) return [];

    const numOfOverlapping = this.btPairCachingGhostObject.getNumOverlappingObjects();
    const collisions: OverlapData[] = [];

    for (let overlapIndex = 0; overlapIndex < numOfOverlapping; overlapIndex++) {
      const overlapObject = this.btPairCachingGhostObject.getOverlappingObject(overlapIndex);
      const overlapComponent = this.entity.app.componentManager.getComponentByIndex(overlapObject.getUserIndex());

      if (overlapComponent) {
        const colliderComponent = overlapComponent.entity.getComponentOrFail(ColliderComponent);

        collisions.push({ colliderComponent });
      }
    }

    return collisions;
  }

  public applyEntityWorldMatrix(): void {
    if (!this.btPairCachingGhostObject) return;

    const transform: Ammo.btTransform = new Ammo.btTransform();

    this.entity.updateMatrixWorld(true);
    this.entity.updateWorldMatrix(true, true);
    const matrix = this.entity.matrixWorld.toArray();
    transform.setFromOpenGLMatrix(matrix);

    this.btPairCachingGhostObject.setWorldTransform(transform);
  }

  public destroy(): void {
    Ammo.destroy(this.btCollisionShape);
  }

  protected getDefaultShapeData(): BoxShapeData {
    return {
      type: ColliderType.Box,
      boxHalfExtents: new Three.Vector3(0.5, 0.5, 0.5),
    };
  }

  protected setupBtCollisionShape(): void {
    this.btCollisionShape = this.makeBtCollisionShape(this.shapeData);

    // todo: think about it
    if (!this.btCollisionShape && this.shapeData.type === ColliderType.TriangleMesh) {
      const meshComponent = this.entity.getComponent(MeshRendererComponent);

      if (!meshComponent) return;

      // TODO: it is not quite right to load the collider via MeshRenderer, need a resource manager
      meshComponent.events.once('contentAdded', () => {
        this.events.emit('beforeUpdate');
        this.btCollisionShape = this.makeBtCollisionShape(this.shapeData);
        this.events.emit('afterUpdate');
      });
    }
  }

  protected setupBtGhostObject(): void {
    if (!this.isTrigger) return;
    if (!this.btCollisionShape) {
      this.events.once('afterUpdate', () => this.setupBtGhostObject());
      return;
    }

    this.btPairCachingGhostObject = new Ammo.btPairCachingGhostObject();
    this.btPairCachingGhostObject.setUserIndex(this.index);
    this.btPairCachingGhostObject.setCollisionShape(this.btCollisionShape);
    this.btPairCachingGhostObject.setCollisionFlags(AmmoCollisionFlag.CF_NO_CONTACT_RESPONSE); // research it
  }

  protected makeBtCollisionShape(data: ShapeData): Ammo.btCollisionShape | undefined {
    switch (data.type) {
      case ColliderType.Box:
        return new Ammo.btBoxShape(new Ammo.btVector3(
          data.boxHalfExtents.x,
          data.boxHalfExtents.y,
          data.boxHalfExtents.z,
        ));
      case ColliderType.Capsule:
        return new Ammo.btCapsuleShape(data.radius, data.height);
      case ColliderType.TriangleMesh:
        return this.makeAmmoTriMeshCollider(data);
      case ColliderType.StaticPlane:
        return new Ammo.btStaticPlaneShape(new Ammo.btVector3(0, 1, 0), 0);
      case ColliderType.Compound:
      default:
        throw new Error(`Unsupported collider type ${data.type}`);
    }
  }

  protected makeAmmoTriMeshCollider(data: TriangleMeshShapeData): Ammo.btCollisionShape | undefined {
    const meshComponent = this.entity.getComponent(MeshRendererComponent);
    const mesh = meshComponent?.data.getObjectByName(data.meshName);

    if (!(mesh instanceof Three.Mesh)) {
      return;
    }

    return ThreeToAmmoConverter.makeTriMeshCollider(mesh, data.applyTransform);
  }
}
