import * as ThreeMeshUI from 'three-mesh-ui';
import * as Three from 'three';
import { setAbsolutePosition } from '../helpers/index';

type TooltipParams = {
  id: string;
  borderRadius: [number, number, number, number];
  label: string;
  width: number;
  absolutePosition: Three.Vector2;
  visible?: boolean;
};

export function Tooltip(params: TooltipParams): ThreeMeshUI.Block {
  const textWrapper = new ThreeMeshUI.Block({
    width: params.width,
    height: 18 + 18 / 3,
    margin: 0,
    borderRadius: 0,
    textAlign: 'center',
    backgroundOpacity: 0,
    fontColor: new Three.Color().setStyle('#222222'),
  });
  const text = new ThreeMeshUI.Text({
    content: params.label,
  });

  const tooltip = new ThreeMeshUI.Block({
    width: params.width,
    height: 54,
    margin: 0,
    fontSize: 18,
    alignItems: 'center',
    borderRadius: params.borderRadius,
    hiddenOverflow: true,
    contentDirection: 'row',
    justifyContent: 'center',
    padding: 0,
    borderWidth: 0,
    backgroundColor: new Three.Color().setStyle('#ffffff'),
    backgroundOpacity: 1,
    offset: 0.1,
  });

  tooltip.visible = params.visible ?? false;

  tooltip.add(textWrapper);
  textWrapper.add(text);

  tooltip.userData.uiData = {
    id: params.id,
    interactive: false,
  };

  if (params.absolutePosition) {
    // todo: missing types
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    tooltip.autoLayout = false;
    tooltip.addEventListener('added', () => {
      if (tooltip.parent) setAbsolutePosition(tooltip.parent, tooltip, params.absolutePosition);
    });
  }

  return tooltip;
}
