import * as Three from 'three';
import { Application } from '../../../engine/Application';
import { Entity } from '../../../engine/Entity';
import { TPControllerComponent } from '../../components/TPController.component';
import { XRFPControllerComponent } from '../../components/XRFPController.component';
import { CameraComponent } from '../../../engine/components/Camera.component';
import { RigidBodyActivationState, RigidBodyComponent, RigidBodyType } from '../../../engine/components/RigidBody.component';
import { ColliderComponent, ColliderType } from '../../../engine/components/Collider.component';
import { AssetSourceType, MeshRendererComponent } from '../../../engine/components/MeshRenderer.component';
import { AnimatorComponent } from '../../../engine/components/Animator.component';
import { PlayerControlsComponent } from '../../components/PlayerControls.component';
import { FPControllerComponent } from '../../components/FPController.component';

export function setupCharacter(app: Application, threeScene: Three.Scene, spawnTransform?: Three.Matrix4): [Entity, Entity] {
  // todo: think about character entity structure
  const characterEntity = app.entityManager.makeEntity();
  characterEntity.rotation.order = 'YXZ';

  if (spawnTransform) { // maybe need move to teleport (spawn?) system
    characterEntity.matrix.copy(spawnTransform);
    characterEntity.matrix.decompose(characterEntity.position, characterEntity.quaternion, characterEntity.scale);
  }

  characterEntity.position.y = 1;

  const cameraEntity = app.entityManager.makeEntity();
  const avatarEntity = app.entityManager.makeEntity();

  avatarEntity.addComponent(MeshRendererComponent, {
    sourceData: {
      type: AssetSourceType.VRM,
      url: '/assets/avatars/VRM_model_FINAL/Female3.vrm',
      // url: '/assets/AliciaSolid.vrm', // nice girl
    },
  });

  avatarEntity.addComponent(AnimatorComponent, {
    initialActionName: 'idle',
    animationSources: [
      { url: '/assets/avatars/Animations/walk.fbx', clipName: 'walk' },
      { url: '/assets/avatars/Animations/idle.fbx', clipName: 'idle' },
      { url: '/assets/avatars/Animations/back.fbx', clipName: 'back' },
      { url: '/assets/avatars/Animations/left.fbx', clipName: 'left' },
      { url: '/assets/avatars/Animations/right.fbx', clipName: 'right' },
    ],
    actions: [
      {
        name: 'walk',
        clipsData: [
          {
            name: 'walk',
            clipName: 'walk',
            bindings: {
              activeWeight: 'forwardWeight',
              speedMultiplier: 'speed',
            },
          },
          {
            name: 'back',
            clipName: 'back',
            resizeTo: 0.9666666388511658,
            startAt: 0.96 / 2,
            bindings: {
              activeWeight: 'backwardWeight',
              speedMultiplier: 'speed',
            },
          },
          {
            name: 'left',
            clipName: 'left',
            resizeTo: 0.9666666388511658,
            bindings: {
              activeWeight: 'leftStrafeWeight',
              speedMultiplier: 'speed',
            },
          },
          {
            name: 'right',
            clipName: 'right',
            resizeTo: 0.9666666388511658,
            startAt: 0.96 / 2,
            bindings: {
              activeWeight: 'rightStrafeWeight',
              speedMultiplier: 'speed',
            },
          },
          {
            name: 'leftBack',
            clipName: 'left',
            resizeTo: 0.9666666388511658,
            startAt: 0.96 / 2,
            bindings: {
              activeWeight: 'leftBackStrafeWeight',
              speedMultiplier: 'backStrafeSpeed',
            },
          },
          {
            name: 'rightBack',
            clipName: 'right',
            resizeTo: 0.9666666388511658,
            bindings: {
              activeWeight: 'rightBackStrafeWeight',
              speedMultiplier: 'backStrafeSpeed',
            },
          },
        ],
      },
      {
        name: 'idle',
        clipsData: [{
          name: 'idle',
          clipName: 'idle',
        }],
      },
    ],
    parameters: {
      speed: 1,
      backStrafeSpeed: 1,
      forwardWeight: 1,
      backwardWeight: 1,
      leftStrafeWeight: 1,
      rightStrafeWeight: 1,
      leftBackStrafeWeight: 1,
      rightBackStrafeWeight: 1,
    },
  });

  avatarEntity.position.y = -1;

  const tpControllerComponent = characterEntity.addComponent(TPControllerComponent);
  tpControllerComponent.cameraEntity = cameraEntity;
  tpControllerComponent.avatarEntity = avatarEntity;
  tpControllerComponent.lookAtEntity = app.entityManager.makeEntity();
  characterEntity.add(tpControllerComponent.lookAtEntity);
  tpControllerComponent.lookAtEntity.position.y = 0.72;
  tpControllerComponent.lookAtEntity.position.z = -0.05;

  const fpControllerComponent = characterEntity.addComponent(FPControllerComponent);
  fpControllerComponent.cameraEntity = cameraEntity;
  fpControllerComponent.avatarEntity = avatarEntity;
  fpControllerComponent.enabled = false;

  const xRFPControllerComponent = characterEntity.addComponent(XRFPControllerComponent);
  xRFPControllerComponent.cameraEntity = cameraEntity;
  xRFPControllerComponent.avatarEntity = avatarEntity;

  characterEntity.add(avatarEntity);
  cameraEntity.addComponent(CameraComponent);
  characterEntity.addComponent(ColliderComponent, {
    shapeData: {
      type: ColliderType.Capsule,
      radius: 0.3,
      height: 1.3,
    },
  });
  characterEntity.addComponent(RigidBodyComponent, {
    type: RigidBodyType.Dynamic,
    mass: 1,
    activationState: RigidBodyActivationState.AlwaysActive,
  });
  characterEntity.addComponent(PlayerControlsComponent);

  threeScene.add(characterEntity);
  threeScene.add(cameraEntity);

  return [characterEntity, cameraEntity];
}
