import * as ThreeMeshUI from 'three-mesh-ui';
import { normalizeColor } from '../helpers/index';

export type ContainerParams = {
  width: number;
  height: number;
  fontFamily?: string;
  fontTexture?: string;
  justifyContent?: 'start' | 'end' | 'center' | 'space-between' | 'space-around' | 'space-evenly';
  contentDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  alignItems?: 'start' | 'end' | 'center' | 'stretch';
  backgroundColor?: string;
  backgroundOpacity: number;
  borderRadius?: [number, number, number, number];
  autoLayout?: boolean;
};

export function Container(params: ContainerParams, children: ThreeMeshUI.Block[]): ThreeMeshUI.Block {
  const container = new ThreeMeshUI.Block({
    width: params.width,
    height: params.height,
    fontFamily: params.fontFamily,
    fontTexture: params.fontTexture,
    justifyContent: params.justifyContent,
    contentDirection: params.contentDirection,
    backgroundOpacity: params.backgroundOpacity,
    backgroundColor: params.backgroundColor ? normalizeColor(params.backgroundColor) : undefined,
    offset: 0.1,
    borderRadius: params.borderRadius || [0, 0, 0, 0],
  });

  // todo: missing types
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  container.autoLayout = params.autoLayout ?? true;

  if (children.length) container.add(...children);

  return container;
}
