import * as Apollo from '@apollo/client';
import { useEffect, useState } from 'react';
import GUI from 'lil-gui';
import { PlayerExternalApi } from '../../domain/externalApi/PlayerExternalApi';
import { GetExamplesStreetsQueryResult } from '../../types/graphqlWS';
import * as graphql from './api/graphql/index';

export function useTestGUI(
  externalApi: PlayerExternalApi | null,
  graphqlClient: Apollo.ApolloClient<Apollo.NormalizedCacheObject>,
): void {
  const [streetsData, setStreetsData] = useState<{ id: string; name: string }[]>([]);

  useEffect(() => {
    graphqlClient.query<GetExamplesStreetsQueryResult>({ query: graphql.GetExamplesStreets })
      .then((response) => {
        const streets = response.data.availableStreets?.data || [];
        setStreetsData(streets?.map((street) => ({ id: street?.id || '', name: street?.name || '' })));
      });
  }, []);

  useEffect(() => {
    if (streetsData.length === 0) return;
    if (!externalApi) return;

    const gui = new GUI();

    gui.domElement.style.right = 'auto';
    gui.domElement.style.left = '0px';
    gui.close();

    streetsData.forEach((streetData) => {
      gui.add({ [streetData.name]: () => externalApi.loadShopStreetScene(streetData.id) }, streetData.name);
    });

    [1, 4, 10, 14, 16].forEach((index) => {
      const name = `teleport to ${index}`;
      gui.add({ [name]: () => externalApi.teleportToStreetSceneShop(index.toString()) }, name);
    });

    return () => {
      gui.destroy();
    };
  }, [streetsData, externalApi]);
}
