import * as Apollo from '@apollo/client';
import { Application } from '../../engine/Application';
import { StreetGeneratorService } from '../services/StreetGenerator.service';
import { InputSystem } from '../../engine/systems/InputSystem';
import { CameraSystem } from '../../engine/systems/Camera.system';
import { PhysicSystem } from '../../engine/systems/Physic.system';
import { XRInputSystem } from '../../engine/systems/XRInputSystem';
import { XRFPControllerSystem } from '../systems/XRFPController.system';
import { TPControllerSystem } from '../systems/TPController.system';
import { XRStatsSystem } from '../../engine/systems/XRStats.system';
import { UIDocumentSystem } from '../../engine/systems/UIDocument.system';
import { DashboardSystem } from '../systems/Dashboard.system/index';
import { AbstractScene } from '../../engine/scene/AbstractScene';
import { ThreeMemoryCleaner } from '../../engine/services/ThreeMemoryCleaner';
import { setupControllers } from './helpers/setupControllers';
import { setupDashboard } from './helpers/setupDashboard';
import { setupEnvironment } from './helpers/setupEnvironment';
import { setupCharacter } from './helpers/setupCharacter';
import { TeleportSystem } from '../systems/Teleport.system';
import { AnimatorSystem } from '../../engine/systems/Animator.system';
import { PlayerControlsSystem } from '../systems/PlayerControls.system';
import { FPControllerSystem } from '../systems/FPController.system';
import { setupDebugMirror } from './helpers/setupDebugMirror';
import { LinkData } from '../components/Dashboard.component';

export type ShopStreetSceneOptions = {
  graphqlClient: Apollo.ApolloClient<Apollo.NormalizedCacheObject>;
};

export type ShopStreetSceneLoadData = {
  fromStreetId?: string;
  streetId: string;
  placeId?: string;
};

// need STRONG refactoring
export class ShopStreetScene extends AbstractScene {
  public loadedStreetId?: string;

  public streetGeneratorService?: StreetGeneratorService;

  protected graphqlClient: Apollo.ApolloClient<Apollo.NormalizedCacheObject>;

  constructor(options: ShopStreetSceneOptions) {
    super();
    this.graphqlClient = options.graphqlClient;
  }

  public load(app: Application, { fromStreetId, streetId, placeId }: ShopStreetSceneLoadData): Promise<void> {
    const streetGeneratorService = new StreetGeneratorService({
      app,
      streetId,
      graphqlClient: this.graphqlClient,
    });

    return streetGeneratorService.loadStreetData().then(() => {
      this.loadedStreetId = streetId;
      this.setupSystems(app, streetId);
      this.setupScene(app, streetGeneratorService, fromStreetId, placeId);
      this.streetGeneratorService = streetGeneratorService;
    });
  }

  public destroy(app: Application): Promise<void> {
    app.destroyAllSystems();
    app.componentManager.destroyAllComponents();
    ThreeMemoryCleaner.disposeThreeGraph(this.threeScene);

    return Promise.resolve(undefined);
  }

  protected setupScene(
    app: Application,
    streetGeneratorService: StreetGeneratorService,
    fromStreetId?: string,
    placeId?: string,
  ): void {
    this.threeScene.add(...streetGeneratorService.generateEntities());
    const spawnTransform = streetGeneratorService.getSpawnTransform(this.threeScene, fromStreetId, placeId);
    const [characterEntity, cameraEntity] = setupCharacter(app, this.threeScene, spawnTransform);
    setupControllers(app, cameraEntity);
    setupDashboard(
      app,
      characterEntity,
      streetGeneratorService.currentStreetName,
      streetGeneratorService.currentStreetId,
      {
        topLeft: {
          name: streetGeneratorService.streetLinksData.start.left.name,
          id: streetGeneratorService.streetLinksData.start.left.id,
        },
        topMiddle: {
          name: streetGeneratorService.streetLinksData.start.forward.name,
          id: streetGeneratorService.streetLinksData.start.forward.id,
        },
        topRight: {
          name: streetGeneratorService.streetLinksData.start.right.name,
          id: streetGeneratorService.streetLinksData.start.right.id,
        },
        bottomLeft: {
          name: streetGeneratorService.streetLinksData.end.left.name,
          id: streetGeneratorService.streetLinksData.end.left.id,
        },
        bottomMiddle: {
          name: streetGeneratorService.streetLinksData.end.forward.name,
          id: streetGeneratorService.streetLinksData.end.forward.id,
        },
        bottomRight: {
          name: streetGeneratorService.streetLinksData.end.right.name,
          id: streetGeneratorService.streetLinksData.end.right.id,
        },
      },
    );
    setupEnvironment(app, this.threeScene);

    setupDebugMirror(this.threeScene);
  }

  protected setupSystems(app: Application, currentStreetId?: string): void {
    [
      // engine
      new InputSystem({ app }),
      new XRInputSystem({ app }),
      new CameraSystem({ app }),
      new PhysicSystem({ app }),
      new AnimatorSystem({ app }),
      new UIDocumentSystem({ app }),
      new XRStatsSystem({ app }),
      // domain
      new PlayerControlsSystem({ app }),
      new XRFPControllerSystem({ app }),
      new FPControllerSystem({ app }),
      new TPControllerSystem({ app }),
      new DashboardSystem({ app, graphqlClient: this.graphqlClient }),
      new TeleportSystem({ app, graphqlClient: this.graphqlClient, currentStreetId }),
    ].forEach((system) => app.addSystem(system));
  }
}
