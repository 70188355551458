import * as ThreeMeshUI from 'three-mesh-ui';
import { Component } from '../Component';
import { UIDocumentElementState } from '../systems/UIDocument.system';
import { ControllerName } from '../systems/XRInputSystem';
import { ThreeMemoryCleaner } from '../services/ThreeMemoryCleaner';

export type ElementStateDataXRSource = {
  type: 'xr';
  controllerName: ControllerName;
};

export type ElementStateDataBrowserSource = {
  type: 'browser';
  controllerName: 'mouse';
};

export type ElementStateData = {
  state: UIDocumentElementState;
  source?: ElementStateDataXRSource | ElementStateDataBrowserSource;
};

export class UIDocumentComponent extends Component {
  public root: ThreeMeshUI.Block | null = null;

  public elementStateDataList: Record<string, ElementStateData> = {};

  static get code(): string {
    return 'u_i_button';
  }

  public setRoot(data: ThreeMeshUI.Block | null): void {
    if (this.root) this.root.removeFromParent();

    this.root = data;

    if (data) this.entity.add(data);
  }

  public getElementById(id: string): ThreeMeshUI.Block | undefined {
    if (!this.root) return;

    let element: ThreeMeshUI.Block | undefined;

    this.root.traverse((object) => {
      if (element) return;
      if (!(object instanceof ThreeMeshUI.Block)) return;
      if (object.userData?.uiData?.id !== id) return;

      element = object;
    });

    return element;
  }

  public getElementsByIds(ids: string[]): ThreeMeshUI.Block[] {
    const elements: ThreeMeshUI.Block[] = [];

    if (!this.root) return elements;

    this.root.traverse((object) => {
      if (!(object instanceof ThreeMeshUI.Block)) return;
      if (!ids.includes(object.userData?.uiData?.id)) return;

      elements.push(object);
    });

    return elements;
  }

  destroy() {
    this.elementStateDataList = {};
    if (this.root) {
      this.root.removeFromParent();
      ThreeMemoryCleaner.disposeThreeGraph(this.root);
      this.root = null;
    }
  }
}
