import * as Apollo from '@apollo/client';
import * as ThreeMeshUI from 'three-mesh-ui';
import { System, SystemOptions } from '../../../engine/System';
import { UIDocumentComponent } from '../../../engine/components/UIDocument.component';
import { Entity } from '../../../engine/Entity';
import { DashboardComponent, LinkData } from '../../components/Dashboard.component';
import { ButtonId } from './enums/ButtonId';
import { UIDocumentElementState, UIDocumentSystem } from '../../../engine/systems/UIDocument.system';
import { TooltipId } from './enums/TooltipId';
import { makeDashboardTemplate } from './makeDashboardTemplate';
import { ShopStreetScene, ShopStreetSceneLoadData } from '../../scenes/ShopStreetScene';

export type DashboardSystemOptions = {
  graphqlClient: Apollo.ApolloClient<Apollo.NormalizedCacheObject>;
} & SystemOptions;

export class DashboardSystem extends System {
  protected graphqlClient: Apollo.ApolloClient<Apollo.NormalizedCacheObject>;

  constructor(options: DashboardSystemOptions) {
    super(options);
    this.graphqlClient = options.graphqlClient;
  }

  static get code(): string {
    return 'dashboard_u_i';
  }

  public setupUIEntity(screenUIEntity: Entity, scale: number): void {
    const uiComponent = screenUIEntity.getComponentOrFail(UIDocumentComponent);
    const dashboardComponent = screenUIEntity.getComponentOrFail(DashboardComponent);

    uiComponent.setRoot(makeDashboardTemplate(dashboardComponent));
    if (uiComponent.root) uiComponent.root.scale.set(scale, scale, scale);
    ThreeMeshUI.update();
  }

  public onUpdate(ts: number) {
    this.app.componentManager.getComponentsByType(DashboardComponent).forEach((component) => {
      component.entity.visible = this.app.renderer.xr.isPresenting; // todo: temporary disabled
      this.handleActiveButtons(component);
    });
  }

  public handleActiveButtons(component: DashboardComponent): void {
    const uIDocumentComponent = component.entity.getComponentOrFail(UIDocumentComponent);
    const states = uIDocumentComponent.elementStateDataList;
    const activeStreetLinkData = this.getActiveStreetLinkData(uIDocumentComponent);

    if (activeStreetLinkData) return this.loadStreetByLinkData(component, activeStreetLinkData);

    if (states[ButtonId.Exit]?.state === UIDocumentElementState.Active) {
      this.app.renderer.xr.getSession()?.end();
    }

    if (states[ButtonId.Info]?.state === UIDocumentElementState.Active) {
      this.toggleInfo(component);
    }

    if (states[ButtonId.InfoEnabled]?.state === UIDocumentElementState.Active) {
      this.toggleInfo(component);
    }

    if (states[ButtonId.MicrophoneOff]?.state === UIDocumentElementState.Active) {
      this.toggleMicrophone(component);
    }

    if (states[ButtonId.Microphone]?.state === UIDocumentElementState.Active) {
      this.toggleMicrophone(component);
    }

    // prevent hover glitch when buttons replaced
    this.app.getSystemOrFail(UIDocumentSystem).updateState(component.entity.getComponentOrFail(UIDocumentComponent));
  }

  protected toggleInfo(component: DashboardComponent): void {
    component.infoIsActive = !component.infoIsActive;
    const document = component.entity.getComponentOrFail(UIDocumentComponent);
    const tooltipElements = document.getElementsByIds(Object.values(TooltipId));
    const infoOffButton = document.getElementById(ButtonId.Info);
    const infoButton = document.getElementById(ButtonId.InfoEnabled);

    if (infoButton) infoButton.visible = component.infoIsActive;
    if (infoOffButton) infoOffButton.visible = !component.infoIsActive;

    tooltipElements.forEach((element) => {
      element.visible = component.infoIsActive;
    });
  }

  protected toggleMicrophone(component: DashboardComponent): void {
    component.microphoneIsActive = !component.microphoneIsActive;
    const document = component.entity.getComponentOrFail(UIDocumentComponent);
    const microphoneOffButton = document.getElementById(ButtonId.MicrophoneOff);
    const microphoneButton = document.getElementById(ButtonId.Microphone);

    if (microphoneButton) microphoneButton.visible = component.microphoneIsActive;
    if (microphoneOffButton) microphoneOffButton.visible = !component.microphoneIsActive;
  }

  protected getActiveStreetLinkData(uIDocumentComponent: UIDocumentComponent): LinkData | null {
    const states = uIDocumentComponent.elementStateDataList;
    const { linksData } = uIDocumentComponent.entity.getComponentOrFail(DashboardComponent);
    let activeLinkData: LinkData | undefined;

    if (states[ButtonId.TLStreet]?.state === UIDocumentElementState.Active) {
      activeLinkData = linksData.topLeft;
    } else if (states[ButtonId.TMStreet]?.state === UIDocumentElementState.Active) {
      activeLinkData = linksData.topMiddle;
    } else if (states[ButtonId.TRStreet]?.state === UIDocumentElementState.Active) {
      activeLinkData = linksData.topRight;
    } else if (states[ButtonId.BLStreet]?.state === UIDocumentElementState.Active) {
      activeLinkData = linksData.bottomLeft;
    } else if (states[ButtonId.BMStreet]?.state === UIDocumentElementState.Active) {
      activeLinkData = linksData.bottomMiddle;
    } else if (states[ButtonId.BRStreet]?.state === UIDocumentElementState.Active) {
      activeLinkData = linksData.bottomRight;
    }

    if (!activeLinkData?.id) return null;

    return activeLinkData;
  }

  protected loadStreetByLinkData(component: DashboardComponent, linkData: LinkData): void {
    this.app.sceneManager.loadScene<ShopStreetSceneLoadData>(
      new ShopStreetScene({ graphqlClient: this.graphqlClient }),
      { streetId: linkData.id, fromStreetId: component.currentStreetId },
    );
  }
}
