import * as Three from 'three';
import { Component } from '../../engine/Component';
import { Entity } from '../../engine/Entity';

// todo: make configurable (any primitive), refactor
export class FPControllerComponent extends Component {
  public cameraEntity?: Entity;

  public avatarEntity?: Entity;

  public isInitialized = false;

  public cameraPhi = Math.PI / 2; // polar angle (vertical)

  public cameraTheta = 0; // azimuth angle (horizontal)

  public baseVelocity = 1.8;

  public sprintVelocity = 3.6;

  public sprintIsActive = false;

  public movementVector: Three.Vector2 = new Three.Vector2();

  static get code(): string {
    return 'f_p_controller';
  }

  public getCameraEntityOrFail(): Entity {
    if (!this.cameraEntity) throw new Error('Camera entity not found');

    return this.cameraEntity;
  }

  public getAvatarEntityOrFail(): Entity {
    if (!this.avatarEntity) throw new Error('Avatar entity not found');

    return this.avatarEntity;
  }
}
