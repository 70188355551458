import { TouchControl } from '../controls/TouchControl';
import { TouchSensor } from '../sensors/TouchSensor';
import { Application } from '../../../Application';

export type TouchscreenDeviceOptions = {
  app: Application;
};

export class TouchscreenDevice {
  protected app: Application;

  public primaryTouch: TouchControl = new TouchControl();

  public primaryTouchSensor: TouchSensor = new TouchSensor();

  protected onTouchMoveBind: (event: TouchEvent) => void;

  protected onTouchStartBind: (event: TouchEvent) => void;

  protected onTouchEndBind: (event: TouchEvent) => void;

  protected onCanvasBlurBind = this.onCanvasBlur.bind(this);

  protected onCanvasFocusBind = this.onCanvasFocus.bind(this);

  constructor(options: TouchscreenDeviceOptions) {
    this.app = options.app;
    this.onTouchMoveBind = this.onTouchMove.bind(this);
    this.onTouchStartBind = this.onTouchStart.bind(this);
    this.onTouchEndBind = this.onTouchEnd.bind(this);
    this.setupListeners();
  }

  public update(): void {
    this.primaryTouchSensor.applyToControls(this.primaryTouch);
  }

  public destroy(): void {
    this.removeListeners();
  }

  protected onTouchMove(event: TouchEvent): void {
    event.preventDefault();
    const primaryTouchData = event.targetTouches[0];

    this.primaryTouchSensor.positionSensor.threeVector2.set(
      (primaryTouchData.clientX / this.app.renderer.domElement.clientWidth) * 2 - 1,
      -(primaryTouchData.clientY / this.app.renderer.domElement.clientHeight) * 2 + 1,
    );
  }

  protected onTouchStart(event: TouchEvent): void {
    event.preventDefault();

    this.app.renderer.domElement.focus();
    const primaryTouchData = event.targetTouches[0];

    this.primaryTouchSensor.pressSensor.isPressed = true;

    this.primaryTouchSensor.positionSensor.threeVector2.set(
      (primaryTouchData.clientX / this.app.renderer.domElement.clientWidth) * 2 - 1,
      -(primaryTouchData.clientY / this.app.renderer.domElement.clientHeight) * 2 + 1,
    );
  }

  protected onTouchEnd(event: TouchEvent): void {
    event.preventDefault();

    this.primaryTouchSensor.pressSensor.isPressed = false;
  }

  protected onCanvasBlur(): void {
    this.primaryTouchSensor.sleep = true;
  }

  protected onCanvasFocus(): void {
    this.primaryTouchSensor.sleep = false;
  }

  protected setupListeners(): void {
    this.app.renderer.domElement.addEventListener('touchmove', this.onTouchMoveBind);
    this.app.renderer.domElement.addEventListener('touchstart', this.onTouchStartBind);
    this.app.renderer.domElement.addEventListener('touchend', this.onTouchEndBind);
    this.app.renderer.domElement.addEventListener('blur', this.onCanvasBlurBind);
    this.app.renderer.domElement.addEventListener('focus', this.onCanvasFocusBind);
  }

  protected removeListeners(): void {
    this.app.renderer.domElement.removeEventListener('touchmove', this.onTouchMoveBind);
    this.app.renderer.domElement.removeEventListener('touchstart', this.onTouchStartBind);
    this.app.renderer.domElement.removeEventListener('touchend', this.onTouchEndBind);
    this.app.renderer.domElement.removeEventListener('blur', this.onCanvasBlurBind);
    this.app.renderer.domElement.removeEventListener('focus', this.onCanvasFocusBind);
  }
}
