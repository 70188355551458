export const doorModels = [
  'shop_assets/door_1.glb',
  'shop_assets/door_2.glb',
  'shop_assets/door_3.glb',
  'shop_assets/door_4.glb',
  'shop_assets/door_5.glb',
];
export const floorFirstModels = [
  'shop_assets/floorfirst_1.glb',
  'shop_assets/floorfirst_2.glb',
  'shop_assets/floorfirst_3.glb',
  'shop_assets/floorfirst_4.glb',
  'shop_assets/floorfirst_5.glb',
];
export const floorSecondModels = [
  'shop_assets/floorsecond_1.glb',
  'shop_assets/floorsecond_2.glb',
  'shop_assets/floorsecond_3.glb',
  'shop_assets/floorsecond_4.glb',
  'shop_assets/floorsecond_5.glb',
];
export const midWallModels = [
  'shop_assets/midwall_1.glb',
  'shop_assets/midwall_2.glb',
  'shop_assets/midwall_3.glb',
];
export const nameModels = [
  'shop_assets/name_1.glb',
  'shop_assets/name_2.glb',
  'shop_assets/name_3.glb',
  'shop_assets/name_4.glb',
  'shop_assets/name_5.glb',
];

export const roofModels = [
  'shop_assets/roof_1.glb',
  'shop_assets/roof_2.glb',
  'shop_assets/roof_3.glb',
  'shop_assets/roof_4.glb',
  'shop_assets/roof_5.glb',
];

export const umbrellaModels = [
  'shop_assets/umbrella_1.glb',
  'shop_assets/umbrella_2.glb',
  'shop_assets/umbrella_3.glb',
  'shop_assets/umbrella_4.glb',
];

export const viewModels = [
  'shop_assets/view_1.glb',
  'shop_assets/view_2.glb',
  'shop_assets/view_3.glb',
  'shop_assets/view_4.glb',
  'shop_assets/view_5.glb',
];

export const streetModels = [
  'street_assets/street_1.glb',
  'street_assets/street_2.glb',
  'street_assets/street_3.glb',
  'street_assets/street_4.glb',
  'street_assets/street_5.glb',
  'street_assets/street_6.glb',
  'street_assets/street_7.glb',
  'street_assets/street_8.glb',
];

export const streetColliders = [
  'colliders/street_1_col.glb',
  'colliders/street_2_col.glb',
  'colliders/street_3_col.glb',
  'colliders/street_4_col.glb',
  'colliders/street_5_col.glb',
  'colliders/street_6_col.glb',
  'colliders/street_7_col.glb',
  'colliders/street_8_col.glb',
];

export const crossroadModels = [
  'street_assets/crossroad.glb',
];

export const crossroadColliders = [
  'colliders/crossroad_col.glb',
];

export const streetMainModels = [
  'street_assets/street_main.glb',
];

export const streetMainCollider = [
  'colliders/street__main_col.glb',
];

export const streetElementModels = [
  'street_assets/street_el_1.glb',
  'street_assets/street_el_2.glb',
  'street_assets/street_el_3.glb',
  'street_assets/street_el_4.glb',
  'street_assets/street_el_5.glb',
  'street_assets/street_el_6.glb',
];

export const streetElementColliders = [
  'colliders/street_el_1_col.glb',
  'colliders/street_el_2_col.glb',
  'colliders/street_el_3_col.glb',
  'colliders/street_el_4_col.glb',
  'colliders/street_el_5_col.glb',
  'colliders/street_el_6_col.glb',
];
